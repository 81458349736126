/*
1. Prevent padding and border from affecting element width. (https://github.com/mozdevs/cssremedy/issues/4)
2. Allow adding a border to an element by just adding a border-width. (https://github.com/tailwindcss/tailwindcss/pull/116)
*/

*,
::before,
::after {
  box-sizing: border-box; /* 1 */
  border-width: 0; /* 2 */
  border-style: solid; /* 2 */
  border-color: currentColor; /* 2 */
}

::before,
::after {
  --tw-content: "";
}

/*
1. Use a consistent sensible line-height in all browsers.
2. Prevent adjustments of font size after orientation changes in iOS.
3. Use a more readable tab size.
4. Use the user's configured `sans` font-family by default.
*/

html {
  line-height: 1.5; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  -moz-tab-size: 4; /* 3 */
  -o-tab-size: 4;
     tab-size: 4; /* 3 */
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; /* 4 */
}

/*
1. Remove the margin in all browsers.
2. Inherit line-height from `html` so users can set them as a class directly on the `html` element.
*/

body {
  margin: 0; /* 1 */
  line-height: inherit; /* 2 */
}

/*
1. Add the correct height in Firefox.
2. Correct the inheritance of border color in Firefox. (https://bugzilla.mozilla.org/show_bug.cgi?id=190655)
3. Ensure horizontal rules are visible by default.
*/

hr {
  height: 0; /* 1 */
  color: inherit; /* 2 */
  border-top-width: 1px; /* 3 */
}

/*
Add the correct text decoration in Chrome, Edge, and Safari.
*/

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
}

/*
Remove the default font size and weight for headings.
*/

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

/*
Reset links to optimize for opt-in styling instead of opt-out.
*/

a {
  color: inherit;
  text-decoration: inherit;
}

/*
Add the correct font weight in Edge and Safari.
*/

b,
strong {
  font-weight: bolder;
}

/*
1. Use the user's configured `mono` font family by default.
2. Correct the odd `em` font sizing in all browsers.
*/

code,
kbd,
samp,
pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/*
Add the correct font size in all browsers.
*/

small {
  font-size: 80%;
}

/*
Prevent `sub` and `sup` elements from affecting the line height in all browsers.
*/

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/*
1. Remove text indentation from table contents in Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=999088, https://bugs.webkit.org/show_bug.cgi?id=201297)
2. Correct table border color inheritance in all Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=935729, https://bugs.webkit.org/show_bug.cgi?id=195016)
3. Remove gaps between table borders by default.
*/

table {
  text-indent: 0; /* 1 */
  border-color: inherit; /* 2 */
  border-collapse: collapse; /* 3 */
}

/*
1. Change the font styles in all browsers.
2. Remove the margin in Firefox and Safari.
3. Remove default padding in all browsers.
*/

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  font-weight: inherit; /* 1 */
  line-height: inherit; /* 1 */
  color: inherit; /* 1 */
  margin: 0; /* 2 */
  padding: 0; /* 3 */
}

/*
Remove the inheritance of text transform in Edge and Firefox.
*/

button,
select {
  text-transform: none;
}

/*
1. Correct the inability to style clickable types in iOS and Safari.
2. Remove default button styles.
*/

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; /* 1 */
}

/*
Use the modern Firefox focus style for all focusable elements.
*/

:-moz-focusring {
  outline: auto;
}

/*
Remove the additional `:invalid` styles in Firefox. (https://github.com/mozilla/gecko-dev/blob/2f9eacd9d3d995c937b4251a5557d95d494c9be1/layout/style/res/forms.css#L728-L737)
*/

:-moz-ui-invalid {
  box-shadow: none;
}

/*
Add the correct vertical alignment in Chrome and Firefox.
*/

progress {
  vertical-align: baseline;
}

/*
Correct the cursor style of increment and decrement buttons in Safari.
*/

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/*
1. Correct the odd appearance in Chrome and Safari.
2. Correct the outline style in Safari.
*/

[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/*
Remove the inner padding in Chrome and Safari on macOS.
*/

::-webkit-search-decoration {
  -webkit-appearance: none;
}

/*
1. Correct the inability to style clickable types in iOS and Safari.
2. Change font properties to `inherit` in Safari.
*/

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/*
Add the correct display in Chrome and Safari.
*/

summary {
  display: list-item;
}

/*
Removes the default spacing and border for appropriate elements.
*/

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol,
ul,
menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

/*
Prevent resizing textareas horizontally by default.
*/

textarea {
  resize: vertical;
}

/*
1. Reset the default placeholder opacity in Firefox. (https://github.com/tailwindlabs/tailwindcss/issues/3300)
2. Set the default placeholder color to the user's configured gray 400 color.
*/

input::-moz-placeholder, textarea::-moz-placeholder {
  opacity: 1; /* 1 */
  color: #9ca3af; /* 2 */
}

input::placeholder,
textarea::placeholder {
  opacity: 1; /* 1 */
  color: #9ca3af; /* 2 */
}

/*
Set the default cursor for buttons.
*/

button,
[role="button"] {
  cursor: pointer;
}

/*
Make sure disabled buttons don't get the pointer cursor.
*/
:disabled {
  cursor: default;
}

/*
1. Make replaced elements `display: block` by default. (https://github.com/mozdevs/cssremedy/issues/14)
2. Add `vertical-align: middle` to align replaced elements more sensibly by default. (https://github.com/jensimmons/cssremedy/issues/14#issuecomment-634934210)
   This can trigger a poorly considered lint error in some tools but is included by design.
*/

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block; /* 1 */
  vertical-align: middle; /* 2 */
}

/*
Constrain images and videos to the parent width and preserve their intrinsic aspect ratio. (https://github.com/mozdevs/cssremedy/issues/14)
*/

img,
video {
  max-width: 100%;
  height: auto;
}

*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}
::-webkit-backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}
::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}
.pointer-events-none {
  pointer-events: none;
}
.\!pointer-events-auto {
  pointer-events: auto !important;
}
.visible {
  visibility: visible;
}
.invisible {
  visibility: hidden;
}
.static {
  position: static;
}
.fixed {
  position: fixed;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
}
.bottom-0 {
  bottom: 0px;
}
.bottom-\[-16px\] {
  bottom: -16px;
}
.bottom-\[-1px\] {
  bottom: -1px;
}
.bottom-\[-2px\] {
  bottom: -2px;
}
.bottom-\[-4px\] {
  bottom: -4px;
}
.bottom-\[-6px\] {
  bottom: -6px;
}
.bottom-\[-7px\] {
  bottom: -7px;
}
.bottom-\[12px\] {
  bottom: 12px;
}
.bottom-\[1px\] {
  bottom: 1px;
}
.bottom-\[20px\] {
  bottom: 20px;
}
.bottom-\[6px\] {
  bottom: 6px;
}
.left-0 {
  left: 0px;
}
.left-2 {
  left: 0.5rem;
}
.left-\[-12px\] {
  left: -12px;
}
.left-\[-1px\] {
  left: -1px;
}
.left-\[-22px\] {
  left: -22px;
}
.left-\[-28px\] {
  left: -28px;
}
.left-\[-2\] {
  left: -2;
}
.left-\[-2px\] {
  left: -2px;
}
.left-\[-40px\] {
  left: -40px;
}
.left-\[-4px\] {
  left: -4px;
}
.left-\[0px\] {
  left: 0px;
}
.left-\[100px\] {
  left: 100px;
}
.left-\[10px\] {
  left: 10px;
}
.left-\[112px\] {
  left: 112px;
}
.left-\[15px\] {
  left: 15px;
}
.left-\[18px\] {
  left: 18px;
}
.left-\[2px\] {
  left: 2px;
}
.left-\[50px\] {
  left: 50px;
}
.left-\[59px\] {
  left: 59px;
}
.left-\[60px\] {
  left: 60px;
}
.left-\[61px\] {
  left: 61px;
}
.left-\[64px\] {
  left: 64px;
}
.left-\[6px\] {
  left: 6px;
}
.left-\[7px\] {
  left: 7px;
}
.left-\[84px\] {
  left: 84px;
}
.left-\[8px\] {
  left: 8px;
}
.left-\[95px\] {
  left: 95px;
}
.left-\[9px\] {
  left: 9px;
}
.right-0 {
  right: 0px;
}
.right-4 {
  right: 1rem;
}
.right-\[-2px\] {
  right: -2px;
}
.right-\[-4px\] {
  right: -4px;
}
.right-\[-7px\] {
  right: -7px;
}
.right-\[14px\] {
  right: 14px;
}
.right-\[15px\] {
  right: 15px;
}
.right-\[1px\] {
  right: 1px;
}
.right-\[25px\] {
  right: 25px;
}
.top-0 {
  top: 0px;
}
.top-1 {
  top: 0.25rem;
}
.top-12 {
  top: 3rem;
}
.top-4 {
  top: 1rem;
}
.top-\[-10px\] {
  top: -10px;
}
.top-\[-12px\] {
  top: -12px;
}
.top-\[-16px\] {
  top: -16px;
}
.top-\[-18px\] {
  top: -18px;
}
.top-\[-1px\] {
  top: -1px;
}
.top-\[-31px\] {
  top: -31px;
}
.top-\[-48px\] {
  top: -48px;
}
.top-\[-6px\] {
  top: -6px;
}
.top-\[-7px\] {
  top: -7px;
}
.top-\[0px\] {
  top: 0px;
}
.top-\[105px\] {
  top: 105px;
}
.top-\[108px\] {
  top: 108px;
}
.top-\[109px\] {
  top: 109px;
}
.top-\[10px\] {
  top: 10px;
}
.top-\[127px\] {
  top: 127px;
}
.top-\[140px\] {
  top: 140px;
}
.top-\[16px\] {
  top: 16px;
}
.top-\[177px\] {
  top: 177px;
}
.top-\[17px\] {
  top: 17px;
}
.top-\[220px\] {
  top: 220px;
}
.top-\[262px\] {
  top: 262px;
}
.top-\[264px\] {
  top: 264px;
}
.top-\[279px\] {
  top: 279px;
}
.top-\[2px\] {
  top: 2px;
}
.top-\[45px\] {
  top: 45px;
}
.top-\[47px\] {
  top: 47px;
}
.top-\[48px\] {
  top: 48px;
}
.top-\[52px\] {
  top: 52px;
}
.top-\[5px\] {
  top: 5px;
}
.top-\[64px\] {
  top: 64px;
}
.top-\[65px\] {
  top: 65px;
}
.top-\[69px\] {
  top: 69px;
}
.top-\[6px\] {
  top: 6px;
}
.top-\[74px\] {
  top: 74px;
}
.top-\[91px\] {
  top: 91px;
}
.top-\[92px\] {
  top: 92px;
}
.top-\[95px\] {
  top: 95px;
}
.top-\[96px\] {
  top: 96px;
}
.z-0 {
  z-index: 0;
}
.z-10 {
  z-index: 10;
}
.z-20 {
  z-index: 20;
}
.z-30 {
  z-index: 30;
}
.z-50 {
  z-index: 50;
}
.z-\[-1\] {
  z-index: -1;
}
.z-\[100\] {
  z-index: 100;
}
.z-\[10\] {
  z-index: 10;
}
.z-\[1\] {
  z-index: 1;
}
.z-\[20\] {
  z-index: 20;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.col-span-2 {
  grid-column: span 2 / span 2;
}
.col-span-3 {
  grid-column: span 3 / span 3;
}
.m-1 {
  margin: 0.25rem;
}
.m-\[-10px\] {
  margin: -10px;
}
.m-\[-1px\] {
  margin: -1px;
}
.m-\[-2px\] {
  margin: -2px;
}
.m-\[1px\] {
  margin: 1px;
}
.m-\[3px\] {
  margin: 3px;
}
.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.mx-\[-10px\] {
  margin-left: -10px;
  margin-right: -10px;
}
.mx-\[-12px\] {
  margin-left: -12px;
  margin-right: -12px;
}
.mx-\[-16px\] {
  margin-left: -16px;
  margin-right: -16px;
}
.mx-\[-20px\] {
  margin-left: -20px;
  margin-right: -20px;
}
.mx-\[-2px\] {
  margin-left: -2px;
  margin-right: -2px;
}
.mx-\[-3px\] {
  margin-left: -3px;
  margin-right: -3px;
}
.mx-\[0px\] {
  margin-left: 0px;
  margin-right: 0px;
}
.mx-\[10px\] {
  margin-left: 10px;
  margin-right: 10px;
}
.mx-\[1px\] {
  margin-left: 1px;
  margin-right: 1px;
}
.mx-\[2px\] {
  margin-left: 2px;
  margin-right: 2px;
}
.mx-\[3px\] {
  margin-left: 3px;
  margin-right: 3px;
}
.mx-\[4px\] {
  margin-left: 4px;
  margin-right: 4px;
}
.mx-\[6px\] {
  margin-left: 6px;
  margin-right: 6px;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.my-\[-1px\] {
  margin-top: -1px;
  margin-bottom: -1px;
}
.my-\[-2px\] {
  margin-top: -2px;
  margin-bottom: -2px;
}
.my-\[10px\] {
  margin-top: 10px;
  margin-bottom: 10px;
}
.my-\[2px\] {
  margin-top: 2px;
  margin-bottom: 2px;
}
.my-\[6px\] {
  margin-top: 6px;
  margin-bottom: 6px;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.mb-5 {
  margin-bottom: 1.25rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mb-8 {
  margin-bottom: 2rem;
}
.mb-\[-14px\] {
  margin-bottom: -14px;
}
.mb-\[-2px\] {
  margin-bottom: -2px;
}
.mb-\[-40px\] {
  margin-bottom: -40px;
}
.mb-\[-6px\] {
  margin-bottom: -6px;
}
.mb-\[2px\] {
  margin-bottom: 2px;
}
.mb-\[5px\] {
  margin-bottom: 5px;
}
.ml-1 {
  margin-left: 0.25rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.ml-3 {
  margin-left: 0.75rem;
}
.ml-4 {
  margin-left: 1rem;
}
.ml-6 {
  margin-left: 1.5rem;
}
.ml-8 {
  margin-left: 2rem;
}
.ml-\[-10px\] {
  margin-left: -10px;
}
.ml-\[-12px\] {
  margin-left: -12px;
}
.ml-\[-14px\] {
  margin-left: -14px;
}
.ml-\[-15px\] {
  margin-left: -15px;
}
.ml-\[-1px\] {
  margin-left: -1px;
}
.ml-\[-2px\] {
  margin-left: -2px;
}
.ml-\[-4px\] {
  margin-left: -4px;
}
.ml-\[-50px\] {
  margin-left: -50px;
}
.ml-\[-5px\] {
  margin-left: -5px;
}
.ml-\[-6px\] {
  margin-left: -6px;
}
.ml-\[10px\] {
  margin-left: 10px;
}
.ml-\[1px\] {
  margin-left: 1px;
}
.ml-\[2px\] {
  margin-left: 2px;
}
.ml-\[3px\] {
  margin-left: 3px;
}
.ml-\[5px\] {
  margin-left: 5px;
}
.ml-\[60px\] {
  margin-left: 60px;
}
.ml-\[6px\] {
  margin-left: 6px;
}
.ml-\[8px\] {
  margin-left: 8px;
}
.ml-auto {
  margin-left: auto;
}
.mr-1 {
  margin-right: 0.25rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.mr-3 {
  margin-right: 0.75rem;
}
.mr-4 {
  margin-right: 1rem;
}
.mr-8 {
  margin-right: 2rem;
}
.mr-\[-15px\] {
  margin-right: -15px;
}
.mr-\[-2px\] {
  margin-right: -2px;
}
.mr-\[-3px\] {
  margin-right: -3px;
}
.mr-\[-4px\] {
  margin-right: -4px;
}
.mr-\[-50px\] {
  margin-right: -50px;
}
.mr-\[-8px\] {
  margin-right: -8px;
}
.mr-\[10px\] {
  margin-right: 10px;
}
.mr-\[1px\] {
  margin-right: 1px;
}
.mr-\[2px\] {
  margin-right: 2px;
}
.mr-\[3px\] {
  margin-right: 3px;
}
.mr-\[4px\] {
  margin-right: 4px;
}
.mr-\[5px\] {
  margin-right: 5px;
}
.mr-\[6px\] {
  margin-right: 6px;
}
.mr-\[7px\] {
  margin-right: 7px;
}
.mr-\[9px\] {
  margin-right: 9px;
}
.mt-1 {
  margin-top: 0.25rem;
}
.mt-12 {
  margin-top: 3rem;
}
.mt-16 {
  margin-top: 4rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mt-5 {
  margin-top: 1.25rem;
}
.mt-6 {
  margin-top: 1.5rem;
}
.mt-7 {
  margin-top: 1.75rem;
}
.mt-8 {
  margin-top: 2rem;
}
.mt-\[-10px\] {
  margin-top: -10px;
}
.mt-\[-12px\] {
  margin-top: -12px;
}
.mt-\[-1px\] {
  margin-top: -1px;
}
.mt-\[-260px\] {
  margin-top: -260px;
}
.mt-\[-28px\] {
  margin-top: -28px;
}
.mt-\[-2px\] {
  margin-top: -2px;
}
.mt-\[-342px\] {
  margin-top: -342px;
}
.mt-\[-37px\] {
  margin-top: -37px;
}
.mt-\[-3px\] {
  margin-top: -3px;
}
.mt-\[-4px\] {
  margin-top: -4px;
}
.mt-\[-8px\] {
  margin-top: -8px;
}
.mt-\[1px\] {
  margin-top: 1px;
}
.mt-\[2px\] {
  margin-top: 2px;
}
.mt-\[340px\] {
  margin-top: 340px;
}
.mt-\[3px\] {
  margin-top: 3px;
}
.mt-\[5px\] {
  margin-top: 5px;
}
.mt-\[6px\] {
  margin-top: 6px;
}
.mt-\[7px\] {
  margin-top: 7px;
}
.mt-auto {
  margin-top: auto;
}
.box-content {
  box-sizing: content-box;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.inline {
  display: inline;
}
.flex {
  display: flex;
}
.inline-flex {
  display: inline-flex;
}
.table {
  display: table;
}
.grid {
  display: grid;
}
.contents {
  display: contents;
}
.hidden {
  display: none;
}
.aspect-\[9\/4\] {
  aspect-ratio: 9/4;
}
.aspect-\[9\/5\] {
  aspect-ratio: 9/5;
}
.aspect-square {
  aspect-ratio: 1 / 1;
}
.h-1 {
  height: 0.25rem;
}
.h-10 {
  height: 2.5rem;
}
.h-12 {
  height: 3rem;
}
.h-16 {
  height: 4rem;
}
.h-2 {
  height: 0.5rem;
}
.h-3 {
  height: 0.75rem;
}
.h-4 {
  height: 1rem;
}
.h-5 {
  height: 1.25rem;
}
.h-6 {
  height: 1.5rem;
}
.h-64 {
  height: 16rem;
}
.h-8 {
  height: 2rem;
}
.h-\[10px\] {
  height: 10px;
}
.h-\[116px\] {
  height: 116px;
}
.h-\[11px\] {
  height: 11px;
}
.h-\[120px\] {
  height: 120px;
}
.h-\[124px\] {
  height: 124px;
}
.h-\[12px\] {
  height: 12px;
}
.h-\[14px\] {
  height: 14px;
}
.h-\[180px\] {
  height: 180px;
}
.h-\[18px\] {
  height: 18px;
}
.h-\[193px\] {
  height: 193px;
}
.h-\[19px\] {
  height: 19px;
}
.h-\[1px\] {
  height: 1px;
}
.h-\[20px\] {
  height: 20px;
}
.h-\[21px\] {
  height: 21px;
}
.h-\[22px\] {
  height: 22px;
}
.h-\[24px\] {
  height: 24px;
}
.h-\[250px\] {
  height: 250px;
}
.h-\[26px\] {
  height: 26px;
}
.h-\[28px\] {
  height: 28px;
}
.h-\[300px\] {
  height: 300px;
}
.h-\[30px\] {
  height: 30px;
}
.h-\[320px\] {
  height: 320px;
}
.h-\[32px\] {
  height: 32px;
}
.h-\[34px\] {
  height: 34px;
}
.h-\[384px\] {
  height: 384px;
}
.h-\[40px\] {
  height: 40px;
}
.h-\[41px\] {
  height: 41px;
}
.h-\[44px\] {
  height: 44px;
}
.h-\[46px\] {
  height: 46px;
}
.h-\[47px\] {
  height: 47px;
}
.h-\[48px\] {
  height: 48px;
}
.h-\[50px\] {
  height: 50px;
}
.h-\[52px\] {
  height: 52px;
}
.h-\[54px\] {
  height: 54px;
}
.h-\[56px\] {
  height: 56px;
}
.h-\[58px\] {
  height: 58px;
}
.h-\[60px\] {
  height: 60px;
}
.h-\[62px\] {
  height: 62px;
}
.h-\[66px\] {
  height: 66px;
}
.h-\[68px\] {
  height: 68px;
}
.h-\[6px\] {
  height: 6px;
}
.h-\[70px\] {
  height: 70px;
}
.h-\[79px\] {
  height: 79px;
}
.h-\[820px\] {
  height: 820px;
}
.h-\[86px\] {
  height: 86px;
}
.h-\[8px\] {
  height: 8px;
}
.h-\[94px\] {
  height: 94px;
}
.h-\[calc\(100\%-40px\)\] {
  height: calc(100% - 40px);
}
.h-full {
  height: 100%;
}
.max-h-\[100\%\] {
  max-height: 100%;
}
.max-h-\[80vh\] {
  max-height: 80vh;
}
.min-h-\[120px\] {
  min-height: 120px;
}
.min-h-\[180px\] {
  min-height: 180px;
}
.min-h-\[200px\] {
  min-height: 200px;
}
.min-h-\[240px\] {
  min-height: 240px;
}
.min-h-\[250px\] {
  min-height: 250px;
}
.min-h-\[32px\] {
  min-height: 32px;
}
.min-h-\[64px\] {
  min-height: 64px;
}
.min-h-\[700px\] {
  min-height: 700px;
}
.min-h-\[80px\] {
  min-height: 80px;
}
.min-h-\[90px\] {
  min-height: 90px;
}
.min-h-screen {
  min-height: 100vh;
}
.w-10 {
  width: 2.5rem;
}
.w-12 {
  width: 3rem;
}
.w-16 {
  width: 4rem;
}
.w-2 {
  width: 0.5rem;
}
.w-20 {
  width: 5rem;
}
.w-24 {
  width: 6rem;
}
.w-3 {
  width: 0.75rem;
}
.w-4 {
  width: 1rem;
}
.w-6 {
  width: 1.5rem;
}
.w-8 {
  width: 2rem;
}
.w-\[100px\] {
  width: 100px;
}
.w-\[102px\] {
  width: 102px;
}
.w-\[104px\] {
  width: 104px;
}
.w-\[105px\] {
  width: 105px;
}
.w-\[10px\] {
  width: 10px;
}
.w-\[110px\] {
  width: 110px;
}
.w-\[11px\] {
  width: 11px;
}
.w-\[120px\] {
  width: 120px;
}
.w-\[124px\] {
  width: 124px;
}
.w-\[125px\] {
  width: 125px;
}
.w-\[128px\] {
  width: 128px;
}
.w-\[12px\] {
  width: 12px;
}
.w-\[130px\] {
  width: 130px;
}
.w-\[137px\] {
  width: 137px;
}
.w-\[13px\] {
  width: 13px;
}
.w-\[14px\] {
  width: 14px;
}
.w-\[150px\] {
  width: 150px;
}
.w-\[15px\] {
  width: 15px;
}
.w-\[160px\] {
  width: 160px;
}
.w-\[164px\] {
  width: 164px;
}
.w-\[168px\] {
  width: 168px;
}
.w-\[16px\] {
  width: 16px;
}
.w-\[170px\] {
  width: 170px;
}
.w-\[173px\] {
  width: 173px;
}
.w-\[175px\] {
  width: 175px;
}
.w-\[17px\] {
  width: 17px;
}
.w-\[180px\] {
  width: 180px;
}
.w-\[187px\] {
  width: 187px;
}
.w-\[18px\] {
  width: 18px;
}
.w-\[192px\] {
  width: 192px;
}
.w-\[19px\] {
  width: 19px;
}
.w-\[200px\] {
  width: 200px;
}
.w-\[202px\] {
  width: 202px;
}
.w-\[208px\] {
  width: 208px;
}
.w-\[20px\] {
  width: 20px;
}
.w-\[210px\] {
  width: 210px;
}
.w-\[21px\] {
  width: 21px;
}
.w-\[220px\] {
  width: 220px;
}
.w-\[22px\] {
  width: 22px;
}
.w-\[240px\] {
  width: 240px;
}
.w-\[24px\] {
  width: 24px;
}
.w-\[250px\] {
  width: 250px;
}
.w-\[260px\] {
  width: 260px;
}
.w-\[26px\] {
  width: 26px;
}
.w-\[27px\] {
  width: 27px;
}
.w-\[28px\] {
  width: 28px;
}
.w-\[290px\] {
  width: 290px;
}
.w-\[291px\] {
  width: 291px;
}
.w-\[300px\] {
  width: 300px;
}
.w-\[305px\] {
  width: 305px;
}
.w-\[30px\] {
  width: 30px;
}
.w-\[320px\] {
  width: 320px;
}
.w-\[32px\] {
  width: 32px;
}
.w-\[336px\] {
  width: 336px;
}
.w-\[33px\] {
  width: 33px;
}
.w-\[34px\] {
  width: 34px;
}
.w-\[350px\] {
  width: 350px;
}
.w-\[35px\] {
  width: 35px;
}
.w-\[36px\] {
  width: 36px;
}
.w-\[375px\] {
  width: 375px;
}
.w-\[37px\] {
  width: 37px;
}
.w-\[380px\] {
  width: 380px;
}
.w-\[38px\] {
  width: 38px;
}
.w-\[39px\] {
  width: 39px;
}
.w-\[400px\] {
  width: 400px;
}
.w-\[40px\] {
  width: 40px;
}
.w-\[41px\] {
  width: 41px;
}
.w-\[42px\] {
  width: 42px;
}
.w-\[43px\] {
  width: 43px;
}
.w-\[444px\] {
  width: 444px;
}
.w-\[44px\] {
  width: 44px;
}
.w-\[46px\] {
  width: 46px;
}
.w-\[47px\] {
  width: 47px;
}
.w-\[48px\] {
  width: 48px;
}
.w-\[50px\] {
  width: 50px;
}
.w-\[52px\] {
  width: 52px;
}
.w-\[534px\] {
  width: 534px;
}
.w-\[54px\] {
  width: 54px;
}
.w-\[56px\] {
  width: 56px;
}
.w-\[58px\] {
  width: 58px;
}
.w-\[60px\] {
  width: 60px;
}
.w-\[62px\] {
  width: 62px;
}
.w-\[640px\] {
  width: 640px;
}
.w-\[65px\] {
  width: 65px;
}
.w-\[66px\] {
  width: 66px;
}
.w-\[680px\] {
  width: 680px;
}
.w-\[68px\] {
  width: 68px;
}
.w-\[700px\] {
  width: 700px;
}
.w-\[70px\] {
  width: 70px;
}
.w-\[72px\] {
  width: 72px;
}
.w-\[74px\] {
  width: 74px;
}
.w-\[76px\] {
  width: 76px;
}
.w-\[80px\] {
  width: 80px;
}
.w-\[82px\] {
  width: 82px;
}
.w-\[85px\] {
  width: 85px;
}
.w-\[86px\] {
  width: 86px;
}
.w-\[88px\] {
  width: 88px;
}
.w-\[8px\] {
  width: 8px;
}
.w-\[90px\] {
  width: 90px;
}
.w-\[970px\] {
  width: 970px;
}
.w-\[calc\(100\%-6px\)\] {
  width: calc(100% - 6px);
}
.w-auto {
  width: auto;
}
.w-full {
  width: 100%;
}
.w-screen {
  width: 100vw;
}
.\!min-w-\[38px\] {
  min-width: 38px !important;
}
.min-w-\[100px\] {
  min-width: 100px;
}
.min-w-\[120px\] {
  min-width: 120px;
}
.min-w-\[132px\] {
  min-width: 132px;
}
.min-w-\[176px\] {
  min-width: 176px;
}
.min-w-\[180px\] {
  min-width: 180px;
}
.min-w-\[186px\] {
  min-width: 186px;
}
.min-w-\[200px\] {
  min-width: 200px;
}
.min-w-\[220px\] {
  min-width: 220px;
}
.min-w-\[300px\] {
  min-width: 300px;
}
.min-w-\[320px\] {
  min-width: 320px;
}
.min-w-\[350px\] {
  min-width: 350px;
}
.min-w-\[48px\] {
  min-width: 48px;
}
.min-w-\[50px\] {
  min-width: 50px;
}
.min-w-\[57px\] {
  min-width: 57px;
}
.min-w-\[58px\] {
  min-width: 58px;
}
.min-w-\[60px\] {
  min-width: 60px;
}
.min-w-\[728px\] {
  min-width: 728px;
}
.min-w-\[80px\] {
  min-width: 80px;
}
.min-w-\[82px\] {
  min-width: 82px;
}
.min-w-\[970px\] {
  min-width: 970px;
}
.min-w-\[97px\] {
  min-width: 97px;
}
.max-w-\[100\%\] {
  max-width: 100%;
}
.max-w-\[100vw\] {
  max-width: 100vw;
}
.max-w-\[1072px\] {
  max-width: 1072px;
}
.max-w-\[140px\] {
  max-width: 140px;
}
.max-w-\[150px\] {
  max-width: 150px;
}
.max-w-\[200px\] {
  max-width: 200px;
}
.max-w-\[220px\] {
  max-width: 220px;
}
.max-w-\[375px\] {
  max-width: 375px;
}
.max-w-\[380px\] {
  max-width: 380px;
}
.max-w-\[500px\] {
  max-width: 500px;
}
.max-w-\[600px\] {
  max-width: 600px;
}
.max-w-\[650px\] {
  max-width: 650px;
}
.max-w-\[780px\] {
  max-width: 780px;
}
.max-w-\[800px\] {
  max-width: 800px;
}
.max-w-\[888px\] {
  max-width: 888px;
}
.max-w-\[900px\] {
  max-width: 900px;
}
.max-w-\[90vw\] {
  max-width: 90vw;
}
.flex-1 {
  flex: 1 1 0%;
}
.flex-shrink-0 {
  flex-shrink: 0;
}
.shrink {
  flex-shrink: 1;
}
.shrink-0 {
  flex-shrink: 0;
}
.flex-grow {
  flex-grow: 1;
}
.flex-grow-0 {
  flex-grow: 0;
}
.grow {
  flex-grow: 1;
}
.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-50 {
  --tw-scale-x: .5;
  --tw-scale-y: .5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-75 {
  --tw-scale-x: .75;
  --tw-scale-y: .75;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.\!cursor-not-allowed {
  cursor: not-allowed !important;
}
.cursor-auto {
  cursor: auto;
}
.cursor-grabbing {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.cursor-ns-resize {
  cursor: ns-resize;
}
.cursor-pointer {
  cursor: pointer;
}
.select-none {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.resize {
  resize: both;
}
.list-disc {
  list-style-type: disc;
}
.grid-flow-col {
  grid-auto-flow: column;
}
.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}
.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}
.grid-cols-\[1fr\] {
  grid-template-columns: 1fr;
}
.grid-cols-\[1fr_1fr\] {
  grid-template-columns: 1fr 1fr;
}
.grid-cols-\[60px_60px\] {
  grid-template-columns: 60px 60px;
}
.grid-cols-\[76px\2c auto\2c 1fr\] {
  grid-template-columns: 76px auto 1fr;
}
.grid-cols-\[auto\2c auto\2c auto\2c auto\] {
  grid-template-columns: auto auto auto auto;
}
.grid-cols-\[auto\2c auto\] {
  grid-template-columns: auto auto;
}
.grid-cols-\[auto\] {
  grid-template-columns: auto;
}
.grid-cols-\[auto_auto\] {
  grid-template-columns: auto auto;
}
.grid-cols-\[minmax\(auto\2c 85px\)\2c auto\] {
  grid-template-columns: minmax(auto,85px) auto;
}
.grid-cols-\[repeat\(1\2c 328px\)\] {
  grid-template-columns: repeat(1,328px);
}
.grid-cols-\[repeat\(2\2c auto\)\] {
  grid-template-columns: repeat(2,auto);
}
.grid-cols-\[repeat\(4\2c 50px\)_58px_208px_repeat\(3\2c 50px\)\] {
  grid-template-columns: repeat(4,50px) 58px 208px repeat(3,50px);
}
.grid-cols-\[repeat\(4\2c 84px\)\] {
  grid-template-columns: repeat(4,84px);
}
.grid-cols-\[repeat\(6\2c 1fr\)\] {
  grid-template-columns: repeat(6,1fr);
}
.grid-cols-\[repeat\(6\2c 44px\)\] {
  grid-template-columns: repeat(6,44px);
}
.grid-cols-\[repeat\(6\2c auto\)\] {
  grid-template-columns: repeat(6,auto);
}
.grid-cols-\[repeat\(7\2c 44px\)\] {
  grid-template-columns: repeat(7,44px);
}
.grid-cols-\[repeat\(7\2c 90px\)\] {
  grid-template-columns: repeat(7,90px);
}
.grid-cols-\[repeat\(8\2c 60px\)\] {
  grid-template-columns: repeat(8,60px);
}
.grid-cols-\[repeat\(9\2c 32px\)\] {
  grid-template-columns: repeat(9,32px);
}
.grid-cols-\[repeat\(auto-fill\2c 26px\)\] {
  grid-template-columns: repeat(auto-fill,26px);
}
.grid-cols-\[repeat\(auto-fill\2c 36px\)\] {
  grid-template-columns: repeat(auto-fill,36px);
}
.grid-cols-\[repeat\(auto-fill\2c minmax\(165px\2c 1fr\)\)\] {
  grid-template-columns: repeat(auto-fill,minmax(165px,1fr));
}
.grid-cols-\[repeat\(auto-fill\2c minmax\(42px\2c 1fr\)\)\] {
  grid-template-columns: repeat(auto-fill,minmax(42px,1fr));
}
.grid-cols-\[repeat\(auto-fill\2c minmax\(44px\2c 1fr\)\)\] {
  grid-template-columns: repeat(auto-fill,minmax(44px,1fr));
}
.grid-cols-\[repeat\(auto-fill\2c minmax\(52px\2c 1fr\)\)\] {
  grid-template-columns: repeat(auto-fill,minmax(52px,1fr));
}
.grid-rows-\[24px_repeat\(16\2c 51px\)\] {
  grid-template-rows: 24px repeat(16,51px);
}
.grid-rows-\[repeat\(12\2c auto\)\] {
  grid-template-rows: repeat(12,auto);
}
.grid-rows-\[repeat\(4\2c 14px\)\] {
  grid-template-rows: repeat(4,14px);
}
.grid-rows-\[repeat\(auto-fill\2c 52px\)\] {
  grid-template-rows: repeat(auto-fill,52px);
}
.flex-row {
  flex-direction: row;
}
.flex-col {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}
.items-center {
  align-items: center;
}
.items-stretch {
  align-items: stretch;
}
.\!justify-start {
  justify-content: flex-start !important;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-evenly {
  justify-content: space-evenly;
}
.gap-1 {
  gap: 0.25rem;
}
.gap-10 {
  gap: 2.5rem;
}
.gap-12 {
  gap: 3rem;
}
.gap-16 {
  gap: 4rem;
}
.gap-2 {
  gap: 0.5rem;
}
.gap-3 {
  gap: 0.75rem;
}
.gap-4 {
  gap: 1rem;
}
.gap-5 {
  gap: 1.25rem;
}
.gap-6 {
  gap: 1.5rem;
}
.gap-8 {
  gap: 2rem;
}
.gap-\[10px\] {
  gap: 10px;
}
.gap-\[16px\] {
  gap: 16px;
}
.gap-\[2px\] {
  gap: 2px;
}
.gap-\[3px\] {
  gap: 3px;
}
.gap-\[5px\] {
  gap: 5px;
}
.gap-\[6px\] {
  gap: 6px;
}
.gap-\[9px\] {
  gap: 9px;
}
.gap-x-2 {
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
}
.gap-x-4 {
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.gap-x-6 {
  -moz-column-gap: 1.5rem;
       column-gap: 1.5rem;
}
.gap-x-\[10px\] {
  -moz-column-gap: 10px;
       column-gap: 10px;
}
.gap-x-\[14px\] {
  -moz-column-gap: 14px;
       column-gap: 14px;
}
.gap-y-2 {
  row-gap: 0.5rem;
}
.gap-y-3 {
  row-gap: 0.75rem;
}
.gap-y-4 {
  row-gap: 1rem;
}
.gap-y-5 {
  row-gap: 1.25rem;
}
.gap-y-6 {
  row-gap: 1.5rem;
}
.gap-y-8 {
  row-gap: 2rem;
}
.gap-y-\[10px\] {
  row-gap: 10px;
}
.gap-y-\[12px\] {
  row-gap: 12px;
}
.gap-y-\[14px\] {
  row-gap: 14px;
}
.gap-y-\[18px\] {
  row-gap: 18px;
}
.self-start {
  align-self: flex-start;
}
.self-end {
  align-self: flex-end;
}
.self-center {
  align-self: center;
}
.self-stretch {
  align-self: stretch;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-clip {
  overflow: clip;
}
.overflow-visible {
  overflow: visible;
}
.overflow-x-auto {
  overflow-x: auto;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.overflow-x-clip {
  overflow-x: clip;
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-clip {
  text-overflow: clip;
}
.whitespace-nowrap {
  white-space: nowrap;
}
.break-normal {
  overflow-wrap: normal;
  word-break: normal;
}
.break-all {
  word-break: break-all;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-\[3px\] {
  border-radius: 3px;
}
.rounded-\[6px\] {
  border-radius: 6px;
}
.rounded-full {
  border-radius: 9999px;
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded-md {
  border-radius: 0.375rem;
}
.rounded-b-none {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.rounded-l {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.rounded-r {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.border {
  border-width: 1px;
}
.border-0 {
  border-width: 0px;
}
.border-2 {
  border-width: 2px;
}
.border-\[3px\] {
  border-width: 3px;
}
.border-x-\[2px\] {
  border-left-width: 2px;
  border-right-width: 2px;
}
.border-y-\[2px\] {
  border-top-width: 2px;
  border-bottom-width: 2px;
}
.border-b {
  border-bottom-width: 1px;
}
.border-b-0 {
  border-bottom-width: 0px;
}
.border-b-2 {
  border-bottom-width: 2px;
}
.border-l {
  border-left-width: 1px;
}
.border-l-0 {
  border-left-width: 0px;
}
.border-l-2 {
  border-left-width: 2px;
}
.border-l-\[3px\] {
  border-left-width: 3px;
}
.border-r {
  border-right-width: 1px;
}
.border-t {
  border-top-width: 1px;
}
.border-solid {
  border-style: solid;
}
.border-\[\#14cc73e0\] {
  border-color: #14cc73e0;
}
.border-\[\#54c3ffe0\] {
  border-color: #54c3ffe0;
}
.border-\[\#bbbbbbe0\] {
  border-color: #bbbbbbe0;
}
.border-\[\#de0ebde0\] {
  border-color: #de0ebde0;
}
.border-\[\#ffc430e0\] {
  border-color: #ffc430e0;
}
.border-amber-9 {
  border-color: var(--amber-9);
}
.border-common {
  border-color: var(--common);
}
.border-common-2 {
  border-color: var(--common-2);
}
.border-epic-2 {
  border-color: var(--epic-2);
}
.border-legendary {
  border-color: var(--legendary);
}
.border-legendary-2 {
  border-color: var(--legendary-2);
}
.border-primary-10 {
  border-color: var(--primary-10);
}
.border-primary-10a {
  border-color: var(--primary-10a);
}
.border-primary-11 {
  border-color: var(--primary-11);
}
.border-primary-12 {
  border-color: var(--primary-12);
}
.border-primary-6 {
  border-color: var(--primary-6);
}
.border-primary-7 {
  border-color: var(--primary-7);
}
.border-rare {
  border-color: var(--rare);
}
.border-rare-2 {
  border-color: var(--rare-2);
}
.border-secondary-11 {
  border-color: var(--secondary-11);
}
.border-teal-9 {
  border-color: var(--teal-9);
}
.border-uncommon {
  border-color: var(--uncommon);
}
.border-uncommon-2 {
  border-color: var(--uncommon-2);
}
.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}
.border-white1 {
  border-color: var(--white1);
}
.border-white2 {
  border-color: var(--white2);
}
.bg-\[\#00000050\] {
  background-color: #00000050;
}
.bg-\[\#00000055\] {
  background-color: #00000055;
}
.bg-\[\#11111155\] {
  background-color: #11111155;
}
.bg-\[\#33333377\] {
  background-color: #33333377;
}
.bg-\[\#37D48822\] {
  background-color: #37D48822;
}
.bg-\[\#44444488\] {
  background-color: #44444488;
}
.bg-\[\#5abff633\] {
  background-color: #5abff633;
}
.bg-\[\#5bc17333\] {
  background-color: #5bc17333;
}
.bg-\[\#6ECCFF22\] {
  background-color: #6ECCFF22;
}
.bg-\[\#BBBBBB22\] {
  background-color: #BBBBBB22;
}
.bg-\[\#DC38C322\] {
  background-color: #DC38C322;
}
.bg-\[\#F1C55522\] {
  background-color: #F1C55522;
}
.bg-\[\#bbbbbb33\] {
  background-color: #bbbbbb33;
}
.bg-\[\#d11ab233\] {
  background-color: #d11ab233;
}
.bg-\[\#f5bf3833\] {
  background-color: #f5bf3833;
}
.bg-amber-9 {
  background-color: var(--amber-9);
}
.bg-common {
  background-color: var(--common);
}
.bg-legendary {
  background-color: var(--legendary);
}
.bg-primary-11 {
  background-color: var(--primary-11);
}
.bg-primary-12 {
  background-color: var(--primary-12);
}
.bg-primary-2a {
  background-color: var(--primary-2a);
}
.bg-primary-4 {
  background-color: var(--primary-4);
}
.bg-rare {
  background-color: var(--rare);
}
.bg-red-9 {
  background-color: var(--red-9);
}
.bg-secondary-4 {
  background-color: var(--secondary-4);
}
.bg-secondary-9 {
  background-color: var(--secondary-9);
}
.bg-teal-9 {
  background-color: var(--teal-9);
}
.bg-tertiary-9 {
  background-color: var(--tertiary-9);
}
.bg-uncommon {
  background-color: var(--uncommon);
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.bg-white2 {
  background-color: var(--white2);
}
.object-cover {
  -o-object-fit: cover;
     object-fit: cover;
}
.p-0 {
  padding: 0px;
}
.p-1 {
  padding: 0.25rem;
}
.p-2 {
  padding: 0.5rem;
}
.p-3 {
  padding: 0.75rem;
}
.p-4 {
  padding: 1rem;
}
.p-5 {
  padding: 1.25rem;
}
.p-6 {
  padding: 1.5rem;
}
.p-\[10px\] {
  padding: 10px;
}
.p-\[11px\] {
  padding: 11px;
}
.p-\[12px\] {
  padding: 12px;
}
.p-\[1px\] {
  padding: 1px;
}
.p-\[2px\] {
  padding: 2px;
}
.p-\[3px\] {
  padding: 3px;
}
.p-\[4px\] {
  padding: 4px;
}
.p-\[5px\] {
  padding: 5px;
}
.p-\[9px\] {
  padding: 9px;
}
.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}
.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}
.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}
.px-\[10px\] {
  padding-left: 10px;
  padding-right: 10px;
}
.px-\[12px\] {
  padding-left: 12px;
  padding-right: 12px;
}
.px-\[14px\] {
  padding-left: 14px;
  padding-right: 14px;
}
.px-\[16px\] {
  padding-left: 16px;
  padding-right: 16px;
}
.px-\[1px\] {
  padding-left: 1px;
  padding-right: 1px;
}
.px-\[2px\] {
  padding-left: 2px;
  padding-right: 2px;
}
.px-\[3px\] {
  padding-left: 3px;
  padding-right: 3px;
}
.px-\[5px\] {
  padding-left: 5px;
  padding-right: 5px;
}
.px-\[6px\] {
  padding-left: 6px;
  padding-right: 6px;
}
.px-\[8px\] {
  padding-left: 8px;
  padding-right: 8px;
}
.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.py-\[10px\] {
  padding-top: 10px;
  padding-bottom: 10px;
}
.py-\[14px\] {
  padding-top: 14px;
  padding-bottom: 14px;
}
.py-\[1px\] {
  padding-top: 1px;
  padding-bottom: 1px;
}
.py-\[200px\] {
  padding-top: 200px;
  padding-bottom: 200px;
}
.py-\[2px\] {
  padding-top: 2px;
  padding-bottom: 2px;
}
.py-\[3px\] {
  padding-top: 3px;
  padding-bottom: 3px;
}
.py-\[4px\] {
  padding-top: 4px;
  padding-bottom: 4px;
}
.py-\[5px\] {
  padding-top: 5px;
  padding-bottom: 5px;
}
.py-\[6px\] {
  padding-top: 6px;
  padding-bottom: 6px;
}
.py-\[7px\] {
  padding-top: 7px;
  padding-bottom: 7px;
}
.py-\[9px\] {
  padding-top: 9px;
  padding-bottom: 9px;
}
.\!pl-6 {
  padding-left: 1.5rem !important;
}
.\!pl-\[12px\] {
  padding-left: 12px !important;
}
.\!pr-5 {
  padding-right: 1.25rem !important;
}
.\!pr-6 {
  padding-right: 1.5rem !important;
}
.\!pr-\[18px\] {
  padding-right: 18px !important;
}
.pb-0 {
  padding-bottom: 0px;
}
.pb-1 {
  padding-bottom: 0.25rem;
}
.pb-10 {
  padding-bottom: 2.5rem;
}
.pb-12 {
  padding-bottom: 3rem;
}
.pb-16 {
  padding-bottom: 4rem;
}
.pb-2 {
  padding-bottom: 0.5rem;
}
.pb-3 {
  padding-bottom: 0.75rem;
}
.pb-4 {
  padding-bottom: 1rem;
}
.pb-5 {
  padding-bottom: 1.25rem;
}
.pb-6 {
  padding-bottom: 1.5rem;
}
.pb-8 {
  padding-bottom: 2rem;
}
.pb-\[10px\] {
  padding-bottom: 10px;
}
.pb-\[1px\] {
  padding-bottom: 1px;
}
.pb-\[28px\] {
  padding-bottom: 28px;
}
.pb-\[2px\] {
  padding-bottom: 2px;
}
.pb-\[3px\] {
  padding-bottom: 3px;
}
.pb-\[4px\] {
  padding-bottom: 4px;
}
.pb-\[5px\] {
  padding-bottom: 5px;
}
.pb-\[6px\] {
  padding-bottom: 6px;
}
.pb-\[8px\] {
  padding-bottom: 8px;
}
.pl-0 {
  padding-left: 0px;
}
.pl-1 {
  padding-left: 0.25rem;
}
.pl-10 {
  padding-left: 2.5rem;
}
.pl-12 {
  padding-left: 3rem;
}
.pl-2 {
  padding-left: 0.5rem;
}
.pl-3 {
  padding-left: 0.75rem;
}
.pl-4 {
  padding-left: 1rem;
}
.pl-5 {
  padding-left: 1.25rem;
}
.pl-6 {
  padding-left: 1.5rem;
}
.pl-7 {
  padding-left: 1.75rem;
}
.pl-8 {
  padding-left: 2rem;
}
.pl-\[10px\] {
  padding-left: 10px;
}
.pl-\[12px\] {
  padding-left: 12px;
}
.pl-\[14px\] {
  padding-left: 14px;
}
.pl-\[16px\] {
  padding-left: 16px;
}
.pl-\[1px\] {
  padding-left: 1px;
}
.pl-\[28px\] {
  padding-left: 28px;
}
.pl-\[2px\] {
  padding-left: 2px;
}
.pl-\[4px\] {
  padding-left: 4px;
}
.pl-\[50px\] {
  padding-left: 50px;
}
.pl-\[52px\] {
  padding-left: 52px;
}
.pl-\[53px\] {
  padding-left: 53px;
}
.pl-\[56px\] {
  padding-left: 56px;
}
.pl-\[6px\] {
  padding-left: 6px;
}
.pl-\[7px\] {
  padding-left: 7px;
}
.pl-\[8px\] {
  padding-left: 8px;
}
.pl-\[9px\] {
  padding-left: 9px;
}
.pl-\[calc\(100vw-100\%\)\] {
  padding-left: calc(100vw - 100%);
}
.pr-0 {
  padding-right: 0px;
}
.pr-1 {
  padding-right: 0.25rem;
}
.pr-14 {
  padding-right: 3.5rem;
}
.pr-2 {
  padding-right: 0.5rem;
}
.pr-3 {
  padding-right: 0.75rem;
}
.pr-4 {
  padding-right: 1rem;
}
.pr-5 {
  padding-right: 1.25rem;
}
.pr-6 {
  padding-right: 1.5rem;
}
.pr-8 {
  padding-right: 2rem;
}
.pr-\[10px\] {
  padding-right: 10px;
}
.pr-\[12px\] {
  padding-right: 12px;
}
.pr-\[14px\] {
  padding-right: 14px;
}
.pr-\[16px\] {
  padding-right: 16px;
}
.pr-\[1px\] {
  padding-right: 1px;
}
.pr-\[22px\] {
  padding-right: 22px;
}
.pr-\[2px\] {
  padding-right: 2px;
}
.pr-\[3px\] {
  padding-right: 3px;
}
.pr-\[4px\] {
  padding-right: 4px;
}
.pr-\[6px\] {
  padding-right: 6px;
}
.pr-\[7px\] {
  padding-right: 7px;
}
.pr-\[8px\] {
  padding-right: 8px;
}
.pt-0 {
  padding-top: 0px;
}
.pt-1 {
  padding-top: 0.25rem;
}
.pt-10 {
  padding-top: 2.5rem;
}
.pt-12 {
  padding-top: 3rem;
}
.pt-16 {
  padding-top: 4rem;
}
.pt-2 {
  padding-top: 0.5rem;
}
.pt-20 {
  padding-top: 5rem;
}
.pt-24 {
  padding-top: 6rem;
}
.pt-3 {
  padding-top: 0.75rem;
}
.pt-4 {
  padding-top: 1rem;
}
.pt-5 {
  padding-top: 1.25rem;
}
.pt-6 {
  padding-top: 1.5rem;
}
.pt-8 {
  padding-top: 2rem;
}
.pt-\[10px\] {
  padding-top: 10px;
}
.pt-\[14px\] {
  padding-top: 14px;
}
.pt-\[18px\] {
  padding-top: 18px;
}
.pt-\[1px\] {
  padding-top: 1px;
}
.pt-\[28px\] {
  padding-top: 28px;
}
.pt-\[2px\] {
  padding-top: 2px;
}
.pt-\[3px\] {
  padding-top: 3px;
}
.pt-\[4px\] {
  padding-top: 4px;
}
.pt-\[5px\] {
  padding-top: 5px;
}
.pt-\[6px\] {
  padding-top: 6px;
}
.pt-\[7px\] {
  padding-top: 7px;
}
.pt-\[8px\] {
  padding-top: 8px;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.font-merienda {
  font-family: "Merienda", "Montserrat", "Roboto";
}
.font-montserrat {
  font-family: "Montserrat", "Roboto";
}
.font-roboto {
  font-family: Roboto;
}
.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}
.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}
.text-\[10px\] {
  font-size: 10px;
}
.text-\[11px\] {
  font-size: 11px;
}
.text-\[12px\] {
  font-size: 12px;
}
.text-\[13px\] {
  font-size: 13px;
}
.text-\[14px\] {
  font-size: 14px;
}
.text-\[15px\] {
  font-size: 15px;
}
.text-\[16px\] {
  font-size: 16px;
}
.text-\[17px\] {
  font-size: 17px;
}
.text-\[18px\] {
  font-size: 18px;
}
.text-\[20px\] {
  font-size: 20px;
}
.text-\[22px\] {
  font-size: 22px;
}
.text-\[25px\] {
  font-size: 25px;
}
.text-\[26px\] {
  font-size: 26px;
}
.text-\[28px\] {
  font-size: 28px;
}
.text-\[36px\] {
  font-size: 36px;
}
.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.font-bold {
  font-weight: 700;
}
.font-medium {
  font-weight: 500;
}
.font-normal {
  font-weight: 400;
}
.font-semibold {
  font-weight: 600;
}
.uppercase {
  text-transform: uppercase;
}
.italic {
  font-style: italic;
}
.leading-\[1\.1\] {
  line-height: 1.1;
}
.leading-\[15px\] {
  line-height: 15px;
}
.leading-\[18px\] {
  line-height: 18px;
}
.leading-\[20px\] {
  line-height: 20px;
}
.leading-\[22px\] {
  line-height: 22px;
}
.leading-\[24px\] {
  line-height: 24px;
}
.leading-\[26px\] {
  line-height: 26px;
}
.leading-\[28px\] {
  line-height: 28px;
}
.leading-\[48px\] {
  line-height: 48px;
}
.leading-none {
  line-height: 1;
}
.leading-normal {
  line-height: 1.5;
}
.leading-snug {
  line-height: 1.375;
}
.leading-tight {
  line-height: 1.25;
}
.tracking-wide {
  letter-spacing: 0.025em;
}
.text-\[\#efd36c\] {
  --tw-text-opacity: 1;
  color: rgb(239 211 108 / var(--tw-text-opacity));
}
.text-\[\#fffbd1\] {
  --tw-text-opacity: 1;
  color: rgb(255 251 209 / var(--tw-text-opacity));
}
.text-accent {
  --tw-text-opacity: 1;
  color: rgb(18 165 148 / var(--tw-text-opacity));
}
.text-amber-9 {
  color: var(--amber-9);
}
.text-bronze-11 {
  color: var(--bronze-11);
}
.text-common {
  color: var(--common);
}
.text-epic {
  color: var(--epic);
}
.text-epic-2 {
  color: var(--epic-2);
}
.text-gold-11 {
  color: var(--gold-11);
}
.text-green-11 {
  color: var(--green-11);
}
.text-green-9 {
  color: var(--green-9);
}
.text-legendary {
  color: var(--legendary);
}
.text-legendary-2 {
  color: var(--legendary-2);
}
.text-legendary-3 {
  color: var(--legendary-3);
}
.text-primary-11 {
  color: var(--primary-11);
}
.text-primary-11a {
  color: var(--primary-11a);
}
.text-rare {
  color: var(--rare);
}
.text-rare-2 {
  color: var(--rare-2);
}
.text-red-11 {
  color: var(--red-11);
}
.text-red-9 {
  color: var(--red-9);
}
.text-secondary-11 {
  color: var(--secondary-11);
}
.text-sky-11 {
  color: var(--sky-11);
}
.text-tertiary-11 {
  color: var(--tertiary-11);
}
.text-tomato-11 {
  color: var(--tomato-11);
}
.text-uncommon-2 {
  color: var(--uncommon-2);
}
.text-white1 {
  color: var(--white1);
}
.text-white2 {
  color: var(--white2);
}
.text-white3 {
  color: var(--white3);
}
.text-yellow-11 {
  color: var(--yellow-11);
}
.opacity-0 {
  opacity: 0;
}
.opacity-100 {
  opacity: 1;
}
.opacity-30 {
  opacity: 0.3;
}
.opacity-40 {
  opacity: 0.4;
}
.opacity-50 {
  opacity: 0.5;
}
.opacity-60 {
  opacity: 0.6;
}
.opacity-70 {
  opacity: 0.7;
}
.shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-\[6px_0_5px_-2px_rgba\(0\2c 0\2c 0\2c 0\.6\)\] {
  --tw-shadow: 6px 0 5px -2px rgba(0,0,0,0.6);
  --tw-shadow-colored: 6px 0 5px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.outline {
  outline-style: solid;
}
.ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.grayscale {
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.invert {
  --tw-invert: invert(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.transition {
  transition-property: color, background-color, border-color, outline-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, outline-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, outline-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-opacity {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-transform {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

:root {
  color-scheme: dark;
}

html {
  font-size: 16px;
  line-height: 16px;
}

body {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: rgba(255, 255, 255, 0.87);
  /* background: #121212; */
  /* Set 5 BG */
  background: var(--primary-1);
}

/* So material ui menus don't make everything jump around */
/* @media (min-width: 900px) {
  body {
    overflow-y: scroll !important;
  }
} */

.ReactVirtualized__Grid,
.ReactVirtualized__List,
.ReactVirtualized__List:focus,
.ReactVirtualized__Grid:focus {
  outline: none;
}

.MuiTooltip-tooltip {
  font-size: 0.85rem;
}

button:focus {
  outline: none;
}

body.pupeteer .ss-hidden {
  visibility: hidden;
}

body.pupeteer .qc-cmp2-container {
  display: none;
}

body.pupeteer .ss-no-overflow {
  overflow: hidden !important;
}

.no-webkit-preview,
.no-webkit-preview > div {
  -webkit-touch-callout: "none";
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none;
       user-select: none;
}

.wrapped-num {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

.text-xll {
  font-size: 1.375rem;
}

.home-bookmarks {
  min-height: 312px;
}

.player-bookmarks-0 .home-bookmarks {
  min-height: 116px;
}

.player-bookmarks-1 .home-bookmarks {
  min-height: 52px;
}

.player-bookmarks-2 .home-bookmarks {
  min-height: 116px;
}

.player-bookmarks-3 .home-bookmarks {
  min-height: 116px;
}

.player-bookmarks-4 .home-bookmarks {
  min-height: 180px;
}

.player-bookmarks-5 .home-bookmarks {
  min-height: 180px;
}

.player-bookmarks-6 .home-bookmarks {
  min-height: 244px;
}

.player-bookmarks-7 .home-bookmarks {
  min-height: 244px;
}

.player-bookmarks-8 .home-bookmarks {
  min-height: 308px;
}

.player-bookmarks-9 .home-bookmarks {
  min-height: 308px;
}

@media (min-width: 900px) {
  .home-bookmarks {
    min-height: 244px;
  }

  .player-bookmarks-0 .home-bookmarks {
    min-height: 52px;
  }

  .player-bookmarks-1 .home-bookmarks {
    min-height: 52px;
  }

  .player-bookmarks-2 .home-bookmarks {
    min-height: 52px;
  }

  .player-bookmarks-3 .home-bookmarks {
    min-height: 52px;
  }

  .player-bookmarks-4 .home-bookmarks {
    min-height: 52px;
  }

  .player-bookmarks-5 .home-bookmarks {
    min-height: 116px;
  }

  .player-bookmarks-6 .home-bookmarks {
    min-height: 116px;
  }

  .player-bookmarks-7 .home-bookmarks {
    min-height: 116px;
  }

  .player-bookmarks-8 .home-bookmarks {
    min-height: 116px;
  }

  .player-bookmarks-9 .home-bookmarks {
    min-height: 180px;
  }
}

.y-scroll {
  overflow-y: auto;
}

.y-scroll::-webkit-scrollbar {
  width: 13px;
  background-color: #202022;
}

.y-scroll::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #585859;
  min-height: 24px;
  border: 3px solid #202022;
}

.y-scroll::-webkit-scrollbar-thumb:focus {
  background-color: #838384;
}

.y-scroll::-webkit-scrollbar-thumb:active {
  background-color: #838384;
}

.y-scroll::-webkit-scrollbar-corner {
  background-color: #202022;
}

.x-scroll {
  overflow-y: auto;
}

.x-scroll::-webkit-scrollbar {
  height: 11px;
  background-color: #202022;
}

.x-scroll::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #585859;
  min-height: 24px;
  border: 3px solid #202022;
}

.x-scroll::-webkit-scrollbar-thumb:focus {
  background-color: #838384;
}

.x-scroll::-webkit-scrollbar-thumb:active {
  background-color: #838384;
}

.x-scroll::-webkit-scrollbar-corner {
  background-color: #202022;
}

.report-link {
  z-index: 9 !important;
}

#na-video .brand-container {
  display: none;
}

/*
.bg-common {
  background-color: rgba(187, 187, 187, 0.2);
}

.bg-uncommon {
  background: rgba(55, 212, 136, 0.2);
}

.bg-rare {
  background: rgba(110, 204, 255, 0.2);
}

.bg-epic {
  background: rgba(220, 56, 195, 0.2);
}

.bg-legendary {
  background: rgba(241, 197, 85, 0.2);
}
*/

.player-bookmarks-0 #empty-bookmarks-placeholder:after {
  content: "Bookmark a player in player stats page for quick access";
}

@supports (content-visibility: auto) {
  .lb-season-stats .lb-ss {
    content-visibility: visible;
  }

  .lb-season-stats .lb-ps {
    content-visibility: hidden;
  }

  .lb-patch-stats .lb-ss {
    content-visibility: hidden;
  }

  .lb-patch-stats .lb-ps {
    content-visibility: visible;
  }
}

@supports not (content-visibility: auto) {
  .lb-season-stats .lb-ps {
    display: none;
  }

  .lb-patch-stats .lb-ss {
    display: none;
  }
}

@supports (content-visibility: auto) {
  .cv-auto {
    content-visibility: auto;
  }
}

@supports not (content-visibility: auto) {
  .cv-auto {
    contain: layout style paint;
  }
}

.cv-hidden {
  content-visibility: hidden;
}

.cv-visible {
  content-visibility: visible;
}

.player-matches {
  contain-intrinsic-size: 0 4500px;
}

.player-expandable-match {
  min-height: 188px;
  contain-intrinsic-size: 0 188px;
}

@media (min-width: 640px) {
  .player-expandable-match {
    min-height: 169px;
    contain-intrinsic-size: 0 169px;
  }
}

@media (min-width: 910px) {
  .player-expandable-match {
    min-height: 111px;
    contain-intrinsic-size: 0 111px;
  }
}

.tc-summary {
  contain-intrinsic-size: 0 279px;
}

@media (min-width: 640px) {
  .tc-summary {
    contain-intrinsic-size: 0 316px;
  }
}

@media (min-width: 1280px) {
  .tc-summary {
    contain-intrinsic-size: 0 171px;
  }
}

.text-shadow {
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
}

.text-shadow-strong {
  text-shadow: -1px 0 2px #000, 0 1px 2px #000, 1px 0 2px #000, 0 -1px 2px #000;
}

.top-trends {
  -webkit-animation-duration: 40s;
          animation-duration: 40s;
  -webkit-animation-name: trends-scroll;
          animation-name: trends-scroll;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-direction: forward;
          animation-direction: forward;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

.top-trends-wrap:hover .top-trends {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}

.top-trends-wrap:hover .top-trends-overlay {
  display: flex;
}

.top-trends-overlay {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    hsl(213, 71.2%, 20.2%) 36% 100%
  );
}

@-webkit-keyframes trends-scroll {
  from {
    transform: translate(0px);
  }

  to {
    transform: translate(-4140px);
  }
}

@keyframes trends-scroll {
  from {
    transform: translate(0px);
  }

  to {
    transform: translate(-4140px);
  }
}

.tc-summary-wrap {
  transition: background 0.3s, box-shadow 0.3s;
}

.tc-summary-wrap:hover {
  background: #cccccc22;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mantine-Modal-inner {
  overflow-y: scroll;
}

@media (min-width: 910px) {
  .mantine-Modal-inner {
    padding-left: 47px;
  }
}

.support-aug-marker {
  position: absolute;
  bottom: -1px;
  right: -1px;
  width: 12px;
  height: 12px;
  border-bottom: 1px solid var(--primary-2);
  border-right: 1px solid var(--primary-2);
  background: linear-gradient(
    135deg,
    transparent 0%,
    transparent 46%,
    var(--primary-2) 46%,
    var(--primary-2) 54%,
    var(--green-10) 54%,
    var(--green-10) 100%
  );
}

.carry-aug-marker {
  position: absolute;
  bottom: -1px;
  left: -1px;
  width: 12px;
  height: 12px;
  border-bottom: 1px solid var(--primary-2);
  border-left: 1px solid var(--primary-2);
  background: linear-gradient(
    225deg,
    transparent 0%,
    transparent 46%,
    var(--primary-2) 46%,
    var(--primary-2) 54%,
    var(--secondary-10) 54%,
    var(--secondary-10) 100%
  );
}

.support-aug-marker-lg {
  position: absolute;
  bottom: -1px;
  right: -1px;
  width: 16px;
  height: 16px;
  border-bottom: 1px solid var(--primary-2);
  border-right: 1px solid var(--primary-2);
  background: linear-gradient(
    135deg,
    transparent 0%,
    transparent 46.75%,
    var(--primary-2) 46.75%,
    var(--primary-2) 53.25%,
    var(--green-10) 53.5%,
    var(--green-10) 100%
  );
}

.carry-aug-marker-lg {
  position: absolute;
  bottom: -1px;
  left: -1px;
  width: 16px;
  height: 16px;
  border-bottom: 1px solid var(--primary-2);
  border-left: 1px solid var(--primary-2);
  background: linear-gradient(
    225deg,
    transparent 0%,
    transparent 46.5%,
    var(--primary-2) 46.75%,
    var(--primary-2) 53.25%,
    var(--secondary-10) 53.25%,
    var(--secondary-10) 100%
  );
}

.Mui-disabled {
  color: var(--white3) !important;
}

.pl-search-bg {
  background: var(--primary-6);
}
body.midnight .pl-search-bg {
  background: var(--primary-4);
}

.triangle-clip {
  -webkit-clip-path: polygon(0 0, 100% 0, 0 100%);
          clip-path: polygon(0 0, 100% 0, 0 100%);
}

.border-1-aa {
  box-shadow: 0 0 1px 0px var(--white2) inset, 0 0 1px 0px var(--white2);
}

.icon-Bilgewater {
  -webkit-mask: url(https://ap.tft.tools/static/trait-icons/Set9b_Bilgewater_w.svg);
          mask: url(https://ap.tft.tools/static/trait-icons/Set9b_Bilgewater_w.svg);
  background: var(--tomato-9);
}

.icon-Ixtal {
  -webkit-mask: url(https://ap.tft.tools/static/trait-icons/Set9_Ixtal_w.svg);
          mask: url(https://ap.tft.tools/static/trait-icons/Set9_Ixtal_w.svg);
  background: var(--grass-9);
}

.icon-Shurima {
  -webkit-mask: url(https://ap.tft.tools/static/trait-icons/Set9_Shurima_w.svg);
          mask: url(https://ap.tft.tools/static/trait-icons/Set9_Shurima_w.svg);
  background: var(--amber-9);
}

.icon-ShadowIsles {
  -webkit-mask: url(https://ap.tft.tools/static/trait-icons/Set9_ShadowIsles_w.svg);
          mask: url(https://ap.tft.tools/static/trait-icons/Set9_ShadowIsles_w.svg);
  background: var(--teal-9);
}

.icon-Zaun {
  -webkit-mask: url(https://ap.tft.tools/static/trait-icons/Set9_Zaun_w.svg);
          mask: url(https://ap.tft.tools/static/trait-icons/Set9_Zaun_w.svg);
  background: var(--green-9);
}

.icon-Noxus {
  -webkit-mask: url(https://ap.tft.tools/static/trait-icons/Set9_Noxus_w.svg);
          mask: url(https://ap.tft.tools/static/trait-icons/Set9_Noxus_w.svg);
  background: var(--ruby-9);
}

.icon-Ionia {
  -webkit-mask: url(https://ap.tft.tools/static/trait-icons/Set9_Ionia_w.svg);
          mask: url(https://ap.tft.tools/static/trait-icons/Set9_Ionia_w.svg);
  background: var(--pink-9);
}

.icon-Freljord {
  -webkit-mask: url(https://ap.tft.tools/static/trait-icons/Set9_Freljord_w.svg);
          mask: url(https://ap.tft.tools/static/trait-icons/Set9_Freljord_w.svg);
  background: var(--cyan-9);
}

.icon-Targon {
  -webkit-mask: url(https://ap.tft.tools/static/trait-icons/Set9_Targon_w.svg);
          mask: url(https://ap.tft.tools/static/trait-icons/Set9_Targon_w.svg);
  background: var(--iris-10);
}

.icon-Demacia {
  -webkit-mask: url(https://ap.tft.tools/static/trait-icons/Set9_Demacia_w.svg);
          mask: url(https://ap.tft.tools/static/trait-icons/Set9_Demacia_w.svg);
  background: var(--yellow-11);
}

.icon-Void {
  -webkit-mask: url(https://ap.tft.tools/static/trait-icons/Set9_Void_w.svg);
          mask: url(https://ap.tft.tools/static/trait-icons/Set9_Void_w.svg);
  background: var(--purple-10);
}

.icon-Piltover {
  -webkit-mask: url(https://ap.tft.tools/static/trait-icons/Set9_Piltover_w.svg);
          mask: url(https://ap.tft.tools/static/trait-icons/Set9_Piltover_w.svg);
  background: var(--orange-9);
}

.icon-BandleCity {
  -webkit-mask: url(https://ap.tft.tools/static/trait-icons/Set9_BandleCity2_w.svg);
          mask: url(https://ap.tft.tools/static/trait-icons/Set9_BandleCity2_w.svg);
  background: var(--lime-9);
}

.anomaly-img {
  overflow-wrap: break-word;
}

.hover\:opacity-100:hover {
  opacity: 1;
}

@media (min-width: 375px) {
  .xs\:gap-1 {
    gap: 0.25rem;
  }
  .xs\:gap-y-4 {
    row-gap: 1rem;
  }
  .xs\:px-\[6px\] {
    padding-left: 6px;
    padding-right: 6px;
  }
}

@media (min-width: 640px) {
  .sm\:bottom-\[-32px\] {
    bottom: -32px;
  }
  .sm\:left-\[100px\] {
    left: 100px;
  }
  .sm\:order-1 {
    order: 1;
  }
  .sm\:order-2 {
    order: 2;
  }
  .sm\:col-span-1 {
    grid-column: span 1 / span 1;
  }
  .sm\:m-\[2px\] {
    margin: 2px;
  }
  .sm\:m-\[4px\] {
    margin: 4px;
  }
  .sm\:mx-\[-6px\] {
    margin-left: -6px;
    margin-right: -6px;
  }
  .sm\:mx-\[5px\] {
    margin-left: 5px;
    margin-right: 5px;
  }
  .sm\:mx-\[6px\] {
    margin-left: 6px;
    margin-right: 6px;
  }
  .sm\:mb-3 {
    margin-bottom: 0.75rem;
  }
  .sm\:mb-4 {
    margin-bottom: 1rem;
  }
  .sm\:ml-0 {
    margin-left: 0px;
  }
  .sm\:ml-3 {
    margin-left: 0.75rem;
  }
  .sm\:ml-4 {
    margin-left: 1rem;
  }
  .sm\:ml-6 {
    margin-left: 1.5rem;
  }
  .sm\:ml-\[-24px\] {
    margin-left: -24px;
  }
  .sm\:ml-\[2px\] {
    margin-left: 2px;
  }
  .sm\:mr-4 {
    margin-right: 1rem;
  }
  .sm\:mr-\[10px\] {
    margin-right: 10px;
  }
  .sm\:mr-\[8px\] {
    margin-right: 8px;
  }
  .sm\:mt-0 {
    margin-top: 0px;
  }
  .sm\:mt-1 {
    margin-top: 0.25rem;
  }
  .sm\:mt-\[6px\] {
    margin-top: 6px;
  }
  .sm\:block {
    display: block;
  }
  .sm\:flex {
    display: flex;
  }
  .sm\:hidden {
    display: none;
  }
  .sm\:h-\[124px\] {
    height: 124px;
  }
  .sm\:h-\[34px\] {
    height: 34px;
  }
  .sm\:h-\[640px\] {
    height: 640px;
  }
  .sm\:h-\[715px\] {
    height: 715px;
  }
  .sm\:h-auto {
    height: auto;
  }
  .sm\:w-20 {
    width: 5rem;
  }
  .sm\:w-24 {
    width: 6rem;
  }
  .sm\:w-\[120px\] {
    width: 120px;
  }
  .sm\:w-\[28px\] {
    width: 28px;
  }
  .sm\:w-\[30px\] {
    width: 30px;
  }
  .sm\:w-\[326px\] {
    width: 326px;
  }
  .sm\:w-\[34px\] {
    width: 34px;
  }
  .sm\:w-\[38px\] {
    width: 38px;
  }
  .sm\:w-\[40px\] {
    width: 40px;
  }
  .sm\:w-\[430px\] {
    width: 430px;
  }
  .sm\:w-\[43px\] {
    width: 43px;
  }
  .sm\:w-\[440px\] {
    width: 440px;
  }
  .sm\:w-\[44px\] {
    width: 44px;
  }
  .sm\:w-\[45px\] {
    width: 45px;
  }
  .sm\:w-\[47px\] {
    width: 47px;
  }
  .sm\:w-\[480px\] {
    width: 480px;
  }
  .sm\:w-\[48px\] {
    width: 48px;
  }
  .sm\:w-\[51px\] {
    width: 51px;
  }
  .sm\:w-\[52px\] {
    width: 52px;
  }
  .sm\:w-\[540px\] {
    width: 540px;
  }
  .sm\:w-\[552px\] {
    width: 552px;
  }
  .sm\:w-\[576px\] {
    width: 576px;
  }
  .sm\:w-\[57px\] {
    width: 57px;
  }
  .sm\:w-\[600px\] {
    width: 600px;
  }
  .sm\:w-\[60px\] {
    width: 60px;
  }
  .sm\:w-\[610px\] {
    width: 610px;
  }
  .sm\:w-\[62px\] {
    width: 62px;
  }
  .sm\:w-\[640px\] {
    width: 640px;
  }
  .sm\:w-\[650px\] {
    width: 650px;
  }
  .sm\:w-\[651px\] {
    width: 651px;
  }
  .sm\:w-\[700px\] {
    width: 700px;
  }
  .sm\:w-\[800px\] {
    width: 800px;
  }
  .sm\:w-\[80px\] {
    width: 80px;
  }
  .sm\:w-\[98px\] {
    width: 98px;
  }
  .sm\:w-auto {
    width: auto;
  }
  .sm\:w-full {
    width: 100%;
  }
  .sm\:max-w-\[100vw\] {
    max-width: 100vw;
  }
  .sm\:max-w-\[486px\] {
    max-width: 486px;
  }
  .sm\:max-w-\[694px\] {
    max-width: 694px;
  }
  .sm\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .sm\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .sm\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .sm\:grid-cols-\[120px_1fr_1fr\] {
    grid-template-columns: 120px 1fr 1fr;
  }
  .sm\:grid-cols-\[1fr_1fr\] {
    grid-template-columns: 1fr 1fr;
  }
  .sm\:grid-cols-\[264px_auto\] {
    grid-template-columns: 264px auto;
  }
  .sm\:grid-cols-\[90px_repeat\(10\2c 1fr\)\] {
    grid-template-columns: 90px repeat(10,1fr);
  }
  .sm\:grid-cols-\[repeat\(10\2c 47px\)\] {
    grid-template-columns: repeat(10,47px);
  }
  .sm\:grid-cols-\[repeat\(13\2c 47px\)\] {
    grid-template-columns: repeat(13,47px);
  }
  .sm\:grid-cols-\[repeat\(2\2c 292px\)\] {
    grid-template-columns: repeat(2,292px);
  }
  .sm\:grid-cols-\[repeat\(6\2c 90px\)\] {
    grid-template-columns: repeat(6,90px);
  }
  .sm\:grid-rows-\[repeat\(4\2c auto\)\] {
    grid-template-rows: repeat(4,auto);
  }
  .sm\:flex-row {
    flex-direction: row;
  }
  .sm\:flex-col {
    flex-direction: column;
  }
  .sm\:items-start {
    align-items: flex-start;
  }
  .sm\:items-end {
    align-items: flex-end;
  }
  .sm\:items-center {
    align-items: center;
  }
  .sm\:justify-start {
    justify-content: flex-start;
  }
  .sm\:justify-end {
    justify-content: flex-end;
  }
  .sm\:justify-center {
    justify-content: center;
  }
  .sm\:justify-between {
    justify-content: space-between;
  }
  .sm\:justify-around {
    justify-content: space-around;
  }
  .sm\:gap-10 {
    gap: 2.5rem;
  }
  .sm\:gap-2 {
    gap: 0.5rem;
  }
  .sm\:gap-4 {
    gap: 1rem;
  }
  .sm\:gap-6 {
    gap: 1.5rem;
  }
  .sm\:gap-8 {
    gap: 2rem;
  }
  .sm\:gap-\[9px\] {
    gap: 9px;
  }
  .sm\:gap-y-\[18px\] {
    row-gap: 18px;
  }
  .sm\:overflow-x-visible {
    overflow-x: visible;
  }
  .sm\:rounded {
    border-radius: 0.25rem;
  }
  .sm\:p-0 {
    padding: 0px;
  }
  .sm\:p-3 {
    padding: 0.75rem;
  }
  .sm\:p-4 {
    padding: 1rem;
  }
  .sm\:p-5 {
    padding: 1.25rem;
  }
  .sm\:p-\[14px\] {
    padding: 14px;
  }
  .sm\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .sm\:px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .sm\:px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .sm\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .sm\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .sm\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .sm\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .sm\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .sm\:pb-1 {
    padding-bottom: 0.25rem;
  }
  .sm\:pb-2 {
    padding-bottom: 0.5rem;
  }
  .sm\:pb-4 {
    padding-bottom: 1rem;
  }
  .sm\:pb-\[14px\] {
    padding-bottom: 14px;
  }
  .sm\:pl-0 {
    padding-left: 0px;
  }
  .sm\:pl-10 {
    padding-left: 2.5rem;
  }
  .sm\:pl-12 {
    padding-left: 3rem;
  }
  .sm\:pl-3 {
    padding-left: 0.75rem;
  }
  .sm\:pl-4 {
    padding-left: 1rem;
  }
  .sm\:pl-5 {
    padding-left: 1.25rem;
  }
  .sm\:pl-6 {
    padding-left: 1.5rem;
  }
  .sm\:pr-0 {
    padding-right: 0px;
  }
  .sm\:pr-1 {
    padding-right: 0.25rem;
  }
  .sm\:pr-10 {
    padding-right: 2.5rem;
  }
  .sm\:pr-3 {
    padding-right: 0.75rem;
  }
  .sm\:pr-\[10px\] {
    padding-right: 10px;
  }
  .sm\:pt-0 {
    padding-top: 0px;
  }
  .sm\:pt-1 {
    padding-top: 0.25rem;
  }
  .sm\:pt-2 {
    padding-top: 0.5rem;
  }
  .sm\:pt-4 {
    padding-top: 1rem;
  }
  .sm\:pt-5 {
    padding-top: 1.25rem;
  }
  .sm\:pt-\[14px\] {
    padding-top: 14px;
  }
  .sm\:pt-\[16px\] {
    padding-top: 16px;
  }
  .sm\:pt-\[18px\] {
    padding-top: 18px;
  }
  .sm\:text-\[14px\] {
    font-size: 14px;
  }
  .sm\:text-\[15px\] {
    font-size: 15px;
  }
  .sm\:text-\[16px\] {
    font-size: 16px;
  }
  .sm\:text-\[18px\] {
    font-size: 18px;
  }
  .sm\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .sm\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  .sm\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .sm\:text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  .sm\:leading-none {
    line-height: 1;
  }
}

@media (min-width: 910px) {
  .md\:bottom-\[-48px\] {
    bottom: -48px;
  }
  .md\:left-\[150px\] {
    left: 150px;
  }
  .md\:top-\[109px\] {
    top: 109px;
  }
  .md\:top-\[92px\] {
    top: 92px;
  }
  .md\:order-1 {
    order: 1;
  }
  .md\:order-2 {
    order: 2;
  }
  .md\:mx-\[-150px\] {
    margin-left: -150px;
    margin-right: -150px;
  }
  .md\:mb-6 {
    margin-bottom: 1.5rem;
  }
  .md\:mb-8 {
    margin-bottom: 2rem;
  }
  .md\:ml-3 {
    margin-left: 0.75rem;
  }
  .md\:ml-4 {
    margin-left: 1rem;
  }
  .md\:ml-6 {
    margin-left: 1.5rem;
  }
  .md\:mt-0 {
    margin-top: 0px;
  }
  .md\:mt-6 {
    margin-top: 1.5rem;
  }
  .md\:block {
    display: block;
  }
  .md\:flex {
    display: flex;
  }
  .md\:grid {
    display: grid;
  }
  .md\:hidden {
    display: none;
  }
  .md\:h-\[735px\] {
    height: 735px;
  }
  .md\:h-\[960px\] {
    height: 960px;
  }
  .md\:min-h-\[90px\] {
    min-height: 90px;
  }
  .md\:w-\[34px\] {
    width: 34px;
  }
  .md\:w-\[36px\] {
    width: 36px;
  }
  .md\:w-\[375px\] {
    width: 375px;
  }
  .md\:w-\[40px\] {
    width: 40px;
  }
  .md\:w-\[42\%\] {
    width: 42%;
  }
  .md\:w-\[42px\] {
    width: 42px;
  }
  .md\:w-\[45px\] {
    width: 45px;
  }
  .md\:w-\[46px\] {
    width: 46px;
  }
  .md\:w-\[49px\] {
    width: 49px;
  }
  .md\:w-\[50px\] {
    width: 50px;
  }
  .md\:w-\[52px\] {
    width: 52px;
  }
  .md\:w-\[58\%\] {
    width: 58%;
  }
  .md\:w-\[650px\] {
    width: 650px;
  }
  .md\:w-\[660px\] {
    width: 660px;
  }
  .md\:w-\[684px\] {
    width: 684px;
  }
  .md\:w-\[740px\] {
    width: 740px;
  }
  .md\:w-\[760px\] {
    width: 760px;
  }
  .md\:w-\[800px\] {
    width: 800px;
  }
  .md\:w-\[842px\] {
    width: 842px;
  }
  .md\:w-\[850px\] {
    width: 850px;
  }
  .md\:w-\[868px\] {
    width: 868px;
  }
  .md\:w-\[880px\] {
    width: 880px;
  }
  .md\:w-\[900px\] {
    width: 900px;
  }
  .md\:w-\[915px\] {
    width: 915px;
  }
  .md\:w-\[920px\] {
    width: 920px;
  }
  .md\:w-\[928px\] {
    width: 928px;
  }
  .md\:w-\[940px\] {
    width: 940px;
  }
  .md\:w-\[960px\] {
    width: 960px;
  }
  .md\:w-\[970px\] {
    width: 970px;
  }
  .md\:w-auto {
    width: auto;
  }
  .md\:w-full {
    width: 100%;
  }
  .md\:min-w-\[48px\] {
    min-width: 48px;
  }
  .md\:min-w-\[728px\] {
    min-width: 728px;
  }
  .md\:max-w-\[216px\] {
    max-width: 216px;
  }
  .md\:max-w-\[790px\] {
    max-width: 790px;
  }
  .md\:max-w-\[928px\] {
    max-width: 928px;
  }
  .md\:max-w-\[initial\] {
    max-width: initial;
  }
  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .md\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .md\:grid-cols-\[1fr_1fr_1fr\] {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .md\:grid-cols-\[repeat\(10\2c 46px\)\] {
    grid-template-columns: repeat(10,46px);
  }
  .md\:grid-cols-\[repeat\(3\2c 280px\)\] {
    grid-template-columns: repeat(3,280px);
  }
  .md\:grid-cols-\[repeat\(9\2c 48px\)\] {
    grid-template-columns: repeat(9,48px);
  }
  .md\:grid-cols-\[repeat\(auto-fill\2c minmax\(52px\2c 1fr\)\)\] {
    grid-template-columns: repeat(auto-fill,minmax(52px,1fr));
  }
  .md\:grid-rows-\[repeat\(2\2c minmax\(170px\2c auto\)\)\] {
    grid-template-rows: repeat(2,minmax(170px,auto));
  }
  .md\:grid-rows-\[repeat\(4\2c minmax\(170px\2c auto\)\)\] {
    grid-template-rows: repeat(4,minmax(170px,auto));
  }
  .md\:flex-row {
    flex-direction: row;
  }
  .md\:flex-col {
    flex-direction: column;
  }
  .md\:items-start {
    align-items: flex-start;
  }
  .md\:items-end {
    align-items: flex-end;
  }
  .md\:items-center {
    align-items: center;
  }
  .md\:justify-between {
    justify-content: space-between;
  }
  .md\:gap-0 {
    gap: 0px;
  }
  .md\:gap-10 {
    gap: 2.5rem;
  }
  .md\:gap-12 {
    gap: 3rem;
  }
  .md\:gap-2 {
    gap: 0.5rem;
  }
  .md\:gap-24 {
    gap: 6rem;
  }
  .md\:gap-3 {
    gap: 0.75rem;
  }
  .md\:gap-6 {
    gap: 1.5rem;
  }
  .md\:self-start {
    align-self: flex-start;
  }
  .md\:p-3 {
    padding: 0.75rem;
  }
  .md\:p-4 {
    padding: 1rem;
  }
  .md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .md\:px-\[12px\] {
    padding-left: 12px;
    padding-right: 12px;
  }
  .md\:px-\[14px\] {
    padding-left: 14px;
    padding-right: 14px;
  }
  .md\:px-\[16px\] {
    padding-left: 16px;
    padding-right: 16px;
  }
  .md\:px-\[3px\] {
    padding-left: 3px;
    padding-right: 3px;
  }
  .md\:py-\[14px\] {
    padding-top: 14px;
    padding-bottom: 14px;
  }
  .md\:pb-0 {
    padding-bottom: 0px;
  }
  .md\:pb-1 {
    padding-bottom: 0.25rem;
  }
  .md\:pb-3 {
    padding-bottom: 0.75rem;
  }
  .md\:pb-5 {
    padding-bottom: 1.25rem;
  }
  .md\:pb-\[6px\] {
    padding-bottom: 6px;
  }
  .md\:pl-0 {
    padding-left: 0px;
  }
  .md\:pl-2 {
    padding-left: 0.5rem;
  }
  .md\:pl-6 {
    padding-left: 1.5rem;
  }
  .md\:pl-8 {
    padding-left: 2rem;
  }
  .md\:pl-\[120px\] {
    padding-left: 120px;
  }
  .md\:pl-\[12px\] {
    padding-left: 12px;
  }
  .md\:pl-\[14px\] {
    padding-left: 14px;
  }
  .md\:pl-\[16px\] {
    padding-left: 16px;
  }
  .md\:pl-\[2px\] {
    padding-left: 2px;
  }
  .md\:pl-\[6px\] {
    padding-left: 6px;
  }
  .md\:pr-2 {
    padding-right: 0.5rem;
  }
  .md\:pr-3 {
    padding-right: 0.75rem;
  }
  .md\:pr-4 {
    padding-right: 1rem;
  }
  .md\:pr-\[12px\] {
    padding-right: 12px;
  }
  .md\:pr-\[14px\] {
    padding-right: 14px;
  }
  .md\:pr-\[16px\] {
    padding-right: 16px;
  }
  .md\:pt-0 {
    padding-top: 0px;
  }
  .md\:pt-1 {
    padding-top: 0.25rem;
  }
  .md\:pt-2 {
    padding-top: 0.5rem;
  }
  .md\:pt-4 {
    padding-top: 1rem;
  }
  .md\:text-\[13px\] {
    font-size: 13px;
  }
  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

@media (min-width: 1270px) {
  .lg\:relative {
    position: relative;
  }
  .lg\:ml-0 {
    margin-left: 0px;
  }
  .lg\:ml-1 {
    margin-left: 0.25rem;
  }
  .lg\:ml-6 {
    margin-left: 1.5rem;
  }
  .lg\:mt-8 {
    margin-top: 2rem;
  }
  .lg\:block {
    display: block;
  }
  .lg\:flex {
    display: flex;
  }
  .lg\:hidden {
    display: none;
  }
  .lg\:h-\[60px\] {
    height: 60px;
  }
  .lg\:h-auto {
    height: auto;
  }
  .lg\:min-h-\[40px\] {
    min-height: 40px;
  }
  .lg\:min-h-\[600px\] {
    min-height: 600px;
  }
  .lg\:min-h-\[90px\] {
    min-height: 90px;
  }
  .lg\:w-\[1100px\] {
    width: 1100px;
  }
  .lg\:w-\[1153px\] {
    width: 1153px;
  }
  .lg\:w-\[150px\] {
    width: 150px;
  }
  .lg\:w-\[172px\] {
    width: 172px;
  }
  .lg\:w-\[20px\] {
    width: 20px;
  }
  .lg\:w-\[34px\] {
    width: 34px;
  }
  .lg\:w-\[36px\] {
    width: 36px;
  }
  .lg\:w-\[46px\] {
    width: 46px;
  }
  .lg\:w-\[80px\] {
    width: 80px;
  }
  .lg\:w-\[928px\] {
    width: 928px;
  }
  .lg\:min-w-\[160px\] {
    min-width: 160px;
  }
  .lg\:min-w-\[61px\] {
    min-width: 61px;
  }
  .lg\:min-w-\[728px\] {
    min-width: 728px;
  }
  .lg\:max-w-\[545px\] {
    max-width: 545px;
  }
  .lg\:max-w-\[calc\(100vw-30px\)\] {
    max-width: calc(100vw - 30px);
  }
  .lg\:grid-cols-\[repeat\(7\2c 46px\)\] {
    grid-template-columns: repeat(7,46px);
  }
  .lg\:grid-cols-\[repeat\(9\2c 48px\)\] {
    grid-template-columns: repeat(9,48px);
  }
  .lg\:flex-row {
    flex-direction: row;
  }
  .lg\:flex-col {
    flex-direction: column;
  }
  .lg\:items-start {
    align-items: flex-start;
  }
  .lg\:items-center {
    align-items: center;
  }
  .lg\:justify-start {
    justify-content: flex-start;
  }
  .lg\:justify-between {
    justify-content: space-between;
  }
  .lg\:gap-3 {
    gap: 0.75rem;
  }
  .lg\:overflow-x-auto {
    overflow-x: auto;
  }
  .lg\:pb-0 {
    padding-bottom: 0px;
  }
  .lg\:pl-\[10px\] {
    padding-left: 10px;
  }
  .lg\:pr-2 {
    padding-right: 0.5rem;
  }
  .lg\:pr-6 {
    padding-right: 1.5rem;
  }
  .lg\:pr-\[3px\] {
    padding-right: 3px;
  }
  .lg\:pt-0 {
    padding-top: 0px;
  }
  .lg\:text-\[13px\] {
    font-size: 13px;
  }
  .lg\:text-\[15px\] {
    font-size: 15px;
  }
  .lg\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .lg\:leading-snug {
    line-height: 1.375;
  }
}

@media (min-width: 1530px) {
  .xl\:sticky {
    position: -webkit-sticky;
    position: sticky;
  }
  .xl\:top-\[108px\] {
    top: 108px;
  }
  .xl\:ml-8 {
    margin-left: 2rem;
  }
  .xl\:block {
    display: block;
  }
  .xl\:flex {
    display: flex;
  }
  .xl\:hidden {
    display: none;
  }
  .xl\:h-\[250px\] {
    height: 250px;
  }
  .xl\:min-h-\[250px\] {
    min-height: 250px;
  }
  .xl\:w-6\/12 {
    width: 50%;
  }
  .xl\:w-\[1035px\] {
    width: 1035px;
  }
  .xl\:w-\[1141px\] {
    width: 1141px;
  }
  .xl\:w-\[220px\] {
    width: 220px;
  }
  .xl\:w-\[260px\] {
    width: 260px;
  }
  .xl\:w-\[290px\] {
    width: 290px;
  }
  .xl\:w-\[444px\] {
    width: 444px;
  }
  .xl\:w-\[726px\] {
    width: 726px;
  }
  .xl\:w-\[727px\] {
    width: 727px;
  }
  .xl\:w-\[760px\] {
    width: 760px;
  }
  .xl\:w-\[818px\] {
    width: 818px;
  }
  .xl\:w-\[950px\] {
    width: 950px;
  }
  .xl\:w-\[956px\] {
    width: 956px;
  }
  .xl\:min-w-\[1446px\] {
    min-width: 1446px;
  }
  .xl\:min-w-\[970px\] {
    min-width: 970px;
  }
  .xl\:max-w-\[1141px\] {
    max-width: 1141px;
  }
  .xl\:max-w-\[781px\] {
    max-width: 781px;
  }
  .xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .xl\:grid-cols-\[repeat\(12\2c 48px\)\] {
    grid-template-columns: repeat(12,48px);
  }
  .xl\:grid-cols-\[repeat\(14\2c 48px\)\] {
    grid-template-columns: repeat(14,48px);
  }
  .xl\:grid-cols-\[repeat\(6\2c 46px\)\] {
    grid-template-columns: repeat(6,46px);
  }
  .xl\:flex-row {
    flex-direction: row;
  }
  .xl\:flex-col {
    flex-direction: column;
  }
  .xl\:items-start {
    align-items: flex-start;
  }
  .xl\:justify-start {
    justify-content: flex-start;
  }
  .xl\:justify-between {
    justify-content: space-between;
  }
  .xl\:gap-10 {
    gap: 2.5rem;
  }
  .xl\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .xl\:pl-10 {
    padding-left: 2.5rem;
  }
  .xl\:pl-4 {
    padding-left: 1rem;
  }
  .xl\:pl-\[6px\] {
    padding-left: 6px;
  }
  .xl\:pr-4 {
    padding-right: 1rem;
  }
  .xl\:pr-8 {
    padding-right: 2rem;
  }
  .xl\:pr-\[4px\] {
    padding-right: 4px;
  }
}

@media (min-width: 1770px) {
  .xxl\:min-w-\[300px\] {
    min-width: 300px;
  }
  .xxl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

:root {
  --common: #bbbbbb;
  --uncommon: #14cc73;
  --rare: #54c3ff;
  --epic: #de0ebd;
  --legendary: #ffc430;

  --common-2: #bbbbbb;
  --uncommon-2: #37d488;
  --rare-2: #6eccff;
  --epic-2: #dc38c3;
  --legendary-2: #f1c555;

  --common-3: #bbbbbb;
  --uncommon-3: #5bdb9d;
  --rare-3: #87d5ff;
  --epic-3: #e856d1;
  --legendary-3: #ffd66e;

  --primary-1: hsl(212, 35%, 9.2%);
  --primary-2: hsl(216, 50%, 11.8%);
  --primary-3: hsl(214, 59.4%, 15.3%);
  --primary-4: hsl(214, 65.8%, 17.9%);
  --primary-5: hsl(213, 71.2%, 20.2%);
  --primary-6: hsl(212, 77.4%, 23.1%);
  --primary-7: hsl(211, 85.1%, 27.4%);
  --primary-8: hsl(211, 89.7%, 34.1%);
  --primary-9: hsl(206, 100%, 50%);
  --primary-10: hsl(209, 100%, 60.6%);
  --primary-11: hsl(210, 100%, 66.1%);
  --primary-12: hsl(206, 98%, 95.8%);
  --primary-1a: hsla(0, 0%, 0%, 0);
  --primary-2a: hsla(221, 97.8%, 52.4%, 0.059);
  --primary-3a: hsla(215, 99.3%, 54.2%, 0.135);
  --primary-3a2: hsla(215, 99.3%, 54.2%, 0.07);
  --primary-3a3: hsla(215, 99.3%, 54.2%, 0.09);
  --primary-4a: hsla(215, 99.3%, 53.8%, 0.198);
  --primary-4a2: hsla(214, 70.8%, 17.9%, 90%);
  --primary-5a: hsla(213, 99.4%, 52.8%, 0.252);
  --primary-5a2: hsla(213, 71.2%, 19.2%, 90%);
  --primary-6a: hsla(212, 99.9%, 51.7%, 0.323);
  --primary-7a: hsla(211, 100%, 50.7%, 0.435);
  --primary-8a: hsla(211, 99.8%, 50.9%, 0.597);
  --primary-9a: hsla(205, 100%, 50%, 0.98);
  --primary-10a: hsla(208, 100%, 60.7%, 0.98);
  --primary-11a: hsla(209, 100%, 66.3%, 0.98);
  --primary-12a: hsla(196, 100%, 96.8%, 0.98);

  --secondary-1: hsl(250, 20%, 10.2%);
  --secondary-2: hsl(255, 30.3%, 12.9%);
  --secondary-3: hsl(253, 37%, 18.4%);
  --secondary-4: hsl(252, 40.1%, 22.5%);
  --secondary-5: hsl(252, 42.2%, 26.2%);
  --secondary-6: hsl(251, 44.3%, 31.1%);
  --secondary-7: hsl(250, 46.8%, 38.9%);
  --secondary-8: hsl(250, 51.8%, 51.2%);
  --secondary-9: hsl(252, 56%, 57.5%);
  --secondary-10: hsl(251, 63.2%, 63.2%);
  --secondary-11: hsl(250, 95%, 76.8%);
  --secondary-12: hsl(252, 87%, 96.4%);
  --secondary-1a: hsla(0, 0%, 0%, 0);
  --secondary-2a: hsla(258, 98.2%, 61%, 0.054);
  --secondary-3a: hsla(252, 98.8%, 65.8%, 0.148);
  --secondary-4a: hsla(253, 99.7%, 65.7%, 0.219);
  --secondary-5a: hsla(252, 99.7%, 66.4%, 0.286);
  --secondary-6a: hsla(251, 99.7%, 66.2%, 0.371);
  --secondary-7a: hsla(250, 99.7%, 66.3%, 0.514);
  --secondary-8a: hsla(250, 99.7%, 66.1%, 0.733);
  --secondary-9a: hsla(252, 99.9%, 70.3%, 0.786);
  --secondary-10a: hsla(251, 99.9%, 72.9%, 0.844);
  --secondary-11a: hsla(250, 100%, 77.9%, 0.98);
  --secondary-12a: hsla(254, 100%, 97.5%, 0.98);

  --tertiary-1: hsl(168, 48%, 6.5%);
  --tertiary-2: hsl(169, 77.8%, 7.1%);
  --tertiary-3: hsl(170, 76.1%, 9.2%);
  --tertiary-4: hsl(171, 75.8%, 11%);
  --tertiary-5: hsl(171, 75.7%, 12.8%);
  --tertiary-6: hsl(172, 75.8%, 15.1%);
  --tertiary-7: hsl(172, 76.7%, 18.6%);
  --tertiary-8: hsl(173, 80.2%, 23.7%);
  --tertiary-9: hsl(173, 80%, 36%);
  --tertiary-10: hsl(174, 83.9%, 38.2%);
  --tertiary-11: hsl(174, 90%, 40.7%);
  --tertiary-12: hsl(166, 73%, 93.1%);
  --tertiary-1a: hsla(0, 0%, 0%, 0);
  --tertiary-2a: hsla(171, 100%, 49.2%, 0.031);
  --tertiary-3a: hsla(172, 100%, 49.7%, 0.07);
  --tertiary-4a: hsla(175, 100%, 49.7%, 0.105);
  --tertiary-5a: hsla(174, 98.9%, 50.1%, 0.14);
  --tertiary-6a: hsla(174, 100%, 51.8%, 0.187);
  --tertiary-7a: hsla(173, 99.6%, 53.2%, 0.257);
  --tertiary-8a: hsla(174, 99.6%, 53.3%, 0.366);
  --tertiary-9a: hsla(173, 99.9%, 54.6%, 0.609);
  --tertiary-10a: hsla(174, 99.9%, 53.8%, 0.67);
  --tertiary-11a: hsla(174, 100%, 52%, 0.748);
  --tertiary-12a: hsla(166, 98.6%, 95%, 0.979);

  --red-8: #aa2429;
  --red-9: #e5484d;
  --red-10: #f2555a;
  --red-11: #ff6369;
  --green-8: #236e4a;
  --green-9: #30a46c;
  --green-10: #3cb179;
  --green-11: #4cc38a;
  --blue-8: #0954a5;
  --blue-9: #0091ff;
  --amber-9: #ffb224;
  --tomato-9: #e54d2e;
  --tomato-10: #ec5e41;
  --tomato-11: #f16a50;
  --amber-11: #f1a10d;
  --yellow-9: #fbe32d;
  --yellow-11: #f0c000;
  --brown-11: #dba16e;
  --bronze-11: #cba393;
  --gold-11: #bfa888;
  --sky-11: #2ec8ee;
  --cyan-9: #05a2c2;
  --cyan-11: #00c2d7;
  --orange-9: #f76808;

  --teal-9: #12a594;
  --teal-11: #0ac5b3;

  --lime-9: #bdee63;
  --iris-9: #5b5bd6;
  --iris-10: #6f6de2;
  --pink-9: #d6409f;
  --indigo-10: #5c73e7;
  --purple-9: #8e4ec6;
  --purple-10: #9e63d5;
  --ruby-8: #d01b3f;
  --ruby-9: #e54666;
  --grass-9: #46a758;

  color: var(--primary-12);

  --white1: rgb(234, 246, 255);
  --white2: rgba(234, 246, 255, 0.7);
  --white3: rgba(234, 246, 255, 0.45);
}

#app-bg {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /* background: url("/bg_s70.png") center center / cover no-repeat; */
  /* background: url("https://cdn.tft.tools/general/bg_s70.png?quality=90") center */

  /* background: url("https://ap.tft.tools/img/bg/s85_dark2.jpg?w=960") center
    center / cover no-repeat; */
  /* background: url("https://ap.tft.tools/img/bg/s9_dark_2.jpg?w=960") center
    center / cover no-repeat; */
  background: url("https://ap.tft.tools/img/bg/s11_dark8.jpg?w=1920") center
    center / cover no-repeat;
  /*
  background: url("https://cdn.tft.tools/general/bg_s8_dark.jpg?quality=90")
    center center / cover no-repeat; */
}

body.midnight #app-bg {
  /* background: url("https://ap.tft.tools/img/bg/s85_midnight2.jpg?w=960") center
    center / cover no-repeat; */
  /* background: url("https://ap.tft.tools/img/bg/s9_midnight_2.jpg?w=960") center
    center / cover no-repeat; */
  background: url("https://ap.tft.tools/img/bg/s11_midnight11.jpg?w=1920") center
    center / cover no-repeat;
}
body.midnight #app-bg.s11 {
}

body.light #app-bg {
  /* background: url("https://cdn.tft.tools/general/bg_s8_light.jpg?quality=90")
    center center / cover no-repeat; */
  background: url("https://ap.tft.tools/img/bg/s9_light.jpg?w=960") center
    center / cover no-repeat;
}

body.light {
  --common: #505050bb;
  --uncommon: #11b288;
  --rare: #207ac7;
  --epic: #c440da;
  --legendary: #ffb93b;

  --primary-5b: hsla(208, 95.1%, 85.1%, 0.75);
  --primary-1: hsl(206, 100%, 99.2%);
  --primary-2: hsl(210, 100%, 98%);
  --primary-3: hsl(209, 100%, 96.5%);
  --primary-4: hsl(210, 98.8%, 94%);
  --primary-5: hsl(209, 95%, 90.1%);
  --primary-6: hsl(209, 81.2%, 84.5%);
  --primary-7: hsl(208, 77.5%, 76.9%);
  --primary-8: hsl(206, 81.9%, 65.3%);
  --primary-9: hsl(206, 100%, 50%);
  --primary-10: hsl(208, 100%, 47.3%);
  --primary-11: hsl(211, 100%, 43.2%);
  --primary-12: hsl(211, 100%, 15%);
  --primary-1a: hsla(210, 100%, 51%, 0.016);
  --primary-2a: hsla(210, 100%, 51%, 0.04);
  --primary-3a: hsla(210, 100%, 50.3%, 0.071);
  --primary-4a: hsla(210, 100%, 50.1%, 0.118);
  --primary-5a: hsla(208, 99.1%, 47.1%, 0.189);
  --primary-6a: hsla(209, 99.5%, 45.3%, 0.283);
  --primary-7a: hsla(208, 99.9%, 43.8%, 0.412);
  --primary-8a: hsla(206, 99.8%, 45.1%, 0.632);
  --primary-9a: hsla(206, 100%, 50%, 0.98);
  --primary-10a: hsla(208, 100%, 47.2%, 0.98);
  --primary-11a: hsla(212, 100%, 43%, 0.98);
  --primary-12a: hsla(213, 100%, 14.4%, 0.98);

  --secondary-1: hsl(255, 65%, 99.4%);
  --secondary-2: hsl(252, 100%, 99%);
  --secondary-3: hsl(252, 96.9%, 97.4%);
  --secondary-4: hsl(252, 91.5%, 95.5%);
  --secondary-5: hsl(252, 85.1%, 93%);
  --secondary-6: hsl(252, 77.8%, 89.4%);
  --secondary-7: hsl(252, 71%, 83.7%);
  --secondary-8: hsl(252, 68.6%, 76.3%);
  --secondary-9: hsl(252, 56%, 57.5%);
  --secondary-10: hsl(251, 48.1%, 53.5%);
  --secondary-11: hsl(250, 43%, 48%);
  --secondary-12: hsl(254, 60%, 18.5%);
  --secondary-1a: hsla(270, 94.3%, 34.6%, 0.012);
  --secondary-2a: hsla(252, 100%, 51%, 0.02);
  --secondary-3a: hsla(254, 100%, 50%, 0.051);
  --secondary-4a: hsla(251, 98.3%, 48.2%, 0.087);
  --secondary-5a: hsla(252, 99%, 45.7%, 0.13);
  --secondary-6a: hsla(251, 99.1%, 44%, 0.189);
  --secondary-7a: hsla(252, 99.5%, 41.7%, 0.279);
  --secondary-8a: hsla(252, 100%, 40.7%, 0.4);
  --secondary-9a: hsla(252, 99.9%, 35.8%, 0.663);
  --secondary-10a: hsla(251, 99.6%, 32.5%, 0.691);
  --secondary-11a: hsla(250, 99.8%, 28.4%, 0.726);
  --secondary-12a: hsla(254, 99.5%, 11.9%, 0.926);

  --tertiary-1: hsl(165, 60%, 98.8%);
  --tertiary-2: hsl(169, 64.7%, 96.7%);
  --tertiary-3: hsl(169, 59.8%, 94%);
  --tertiary-4: hsl(169, 53.1%, 90.2%);
  --tertiary-5: hsl(170, 47.1%, 85%);
  --tertiary-6: hsl(170, 42.6%, 77.9%);
  --tertiary-7: hsl(170, 39.9%, 68.1%);
  --tertiary-8: hsl(172, 42.1%, 52.5%);
  --tertiary-9: hsl(173, 80%, 36%);
  --tertiary-10: hsl(173, 83.4%, 32.5%);
  --tertiary-11: hsl(174, 90%, 25.2%);
  --tertiary-12: hsl(170, 50%, 12.5%);
  --tertiary-1a: hsla(165, 95.2%, 41.2%, 0.02);
  --tertiary-2a: hsla(169, 99.5%, 39.4%, 0.055);
  --tertiary-3a: hsla(167, 97.6%, 38.1%, 0.095);
  --tertiary-4a: hsla(168, 98.1%, 34.6%, 0.15);
  --tertiary-5a: hsla(170, 99.4%, 32.3%, 0.22);
  --tertiary-6a: hsla(170, 99.7%, 30.1%, 0.314);
  --tertiary-7a: hsla(170, 99.3%, 28.7%, 0.448);
  --tertiary-8a: hsla(172, 99.8%, 29.7%, 0.675);
  --tertiary-9a: hsla(173, 99.8%, 31.1%, 0.93);
  --tertiary-10a: hsla(173, 99.7%, 28.7%, 0.946);
  --tertiary-11a: hsla(174, 99.8%, 23.3%, 0.977);
  --tertiary-12a: hsla(171, 98.8%, 6.8%, 0.938);
  --red-10: #f2555a;
  --red-11: #ff6369;
  --green-10: #3cb179;
  --green-11: #4cc38a;

  --teal-11: #0ac5b3;
  --amber-9: #ffb224;
  --amber-9a: #ffa600db;
  --amber-10: #ffa01c;
  --amber-10a: #ff9500e3;
  --yellow-9: #f5d90a;
  --yellow-9a: #f5d800f5;
  --yellow-10: #f7ce00;
  --yellow-10a: #f7ce00fa;

  --green-10: #299764;
  --purple-10: #8445bc;

  --white1: rgb(0, 37, 77);
  --white2: rgba(0, 37, 77, 0.7);
  --white3: rgba(0, 37, 77, 0.45);

  color: var(--white1);

  --red-9: #e5484d;
  --green-9: #30a46c;
  --blue-9: #0091ff;
}

/* .title-bar {
  position: fixed;
  top: 0;
  -webkit-user-select: none;
  -webkit-app-region: drag;
  z-index: 1;
  width: 100vw;
  height: 40px;
  background: var(--primary-3a2);
  cursor: grab;
  backdrop-filter: blur(2px);
} */

.bg-bg0 {
  background: var(--primary-5);
}
body.midnight .bg-bg0 {
  background: var(--primary-2);
}
body.light .bg-bg0 {
  background: var(--primary-4);
}

.bg-bg {
  background: var(--primary-5a2);
  transform: translate3d(0, 0, 0);
}
body.midnight .bg-bg {
  background: var(--primary-2);
  -webkit-backdrop-filter: none;
          backdrop-filter: none;
}
body.light .bg-bg {
  background: var(--primary-4);
}

.bg-bg2 {
  background: rgba(255, 255, 255, 0.05);
}
body.midnight .bg-bg2 {
  background: var(--primary-3a2);
}
body.light .bg-bg2 {
  background: var(--primary-3a);
}

.bg-bg2-hover:hover {
  background: rgba(255, 255, 255, 0.05);
}
body.midnight .bg-bg2-hover:hover {
  background: var(--primary-3a2);
}
body.light .bg-bg2-hover:hover {
  background: var(--primary-3a);
}

.bg-bg3 {
  background: rgba(255, 255, 255, 0.08);
}
body.midnight .bg-bg3 {
  background: var(--primary-3a3);
}

.elevation-2 {
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.elevation-4 {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.elevation-6 {
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 5px -1px,
    rgb(0 0 0 / 14%) 0px 6px 10px 0px, rgb(0 0 0 / 12%) 0px 1px 18px 0px;
}

.elevation-8 {
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
}

.elevation-12 {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 7px 8px -4px, rgba(0, 0, 0, 0.14) 0px 12px 17px 2px, rgba(0, 0, 0, 0.12) 0px 5px 22px 4px;
}

.elevation-16 {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 10px -5px, rgba(0, 0, 0, 0.14) 0px 16px 24px 2px, rgba(0, 0, 0, 0.12) 0px 6px 30px 5px;
}

.elevation-24 {
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%),
    0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
}

.how-to-icon {
  transition: all 300ms;
  transform: rotate(0deg);
}

.how-to-icon.active {
  transform: rotate(180deg);
}

.how-to-content {
  transition: margin-top 350ms;
  margin-top: -100%;
}

.how-to-content.active {
  margin-top: 0;
}

.h1 {
  font-size: 1.875rem;
  font-weight: 500;
  font-family: "Montserrat";
}

.h2 {
  font-size: 1.5rem;
  font-weight: 500;
  font-family: "Montserrat";
}

.h3 {
  font-size: 1.25rem;
  font-weight: 500;
  font-family: "Montserrat";
}

.h4 {
  font-size: 1.125rem;
  font-weight: 500;
  font-family: "Montserrat";
}

.truncate-2 {
  width: 52px;
  margin: 0 -2px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  overflow-wrap: break-word;
}

.sticky-header {
  position: -webkit-sticky;
  position: sticky;
  z-index: 20;
  background: var(--primary-4);
}
body.midnight .sticky-header {
  background: var(--primary-2);
}

.tbl-row-sm {
  height: 39px;
}

.tbl-row-md {
  height: 46px;
}

.tbl-row-lg {
  height: 52px;
}

@supports (content-visibility: auto) {
  .tbl-row-sm {
    content-visibility: auto;
  }

  .tbl-row-md {
    content-visibility: auto;
  }

  .tbl-row-lg {
    content-visibility: auto;
  }
}

@supports not (content-visibility: auto) {
  .tbl-row-sm {
    contain: size layout style paint;
  }

  .tbl-row-md {
    contain: size layout style paint;
  }

  .tbl-row-lg {
    contain: size layout style paint;
  }
}

@supports (content-visibility: auto) {
  .tbl-hide-cat1 .tbl-row-cat1 {
    content-visibility: hidden;
    height: 0;
  }

  .tbl-hide-cat2 .tbl-row-cat2 {
    content-visibility: hidden;
    height: 0;
  }

  .tbl-hide-cat3 .tbl-row-cat3 {
    content-visibility: hidden;
    height: 0;
  }

  .tbl-hide-cat4 .tbl-row-cat4 {
    content-visibility: hidden;
    height: 0;
  }

  .tbl-hide-cat5 .tbl-row-cat5 {
    content-visibility: hidden;
    height: 0;
  }

  .tbl-row-hidden {
    content-visibility: hidden;
    height: 0;
  }
}

@supports not (content-visibility: auto) {
  .tbl-hide-cat1 .tbl-row-cat1 {
    display: none;
  }

  .tbl-hide-cat2 .tbl-row-cat2 {
    display: none;
  }

  .tbl-hide-cat3 .tbl-row-cat3 {
    display: none;
  }

  .tbl-hide-cat4 .tbl-row-cat4 {
    display: none;
  }

  .tbl-hide-cat5 .tbl-row-cat5 {
    display: none;
  }

  .tbl-row-hidden {
    display: none;
  }
}

.tbl-body-inner-sm {
  background: repeating-linear-gradient(
    var(--primary-6),
    var(--primary-6) 39px,
    var(--primary-4) 39px,
    var(--primary-4) 78px
  );
}
body.midnight .tbl-body-inner-sm {
  background: repeating-linear-gradient(
    var(--primary-3a2),
    var(--primary-3a2) 39px,
    var(--primary-2) 39px,
    var(--primary-2) 78px
  );
}
body.light .tbl-body-inner-sm {
  background: repeating-linear-gradient(
    var(--primary-5),
    var(--primary-5) 39px,
    var(--primary-4) 39px,
    var(--primary-4) 78px
  );
}

.tbl-body-inner-md {
  background: repeating-linear-gradient(
    var(--primary-6),
    var(--primary-6) 46px,
    var(--primary-4) 46px,
    var(--primary-4) 92px
  );
}
body.midnight .tbl-body-inner-md {
  background: repeating-linear-gradient(
    var(--primary-3a2),
    var(--primary-3a2) 46px,
    var(--primary-2) 46px,
    var(--primary-2) 92px
  );
}
body.light .tbl-body-inner-md {
  background: repeating-linear-gradient(
    var(--primary-5),
    var(--primary-5) 46px,
    var(--primary-4) 46px,
    var(--primary-4) 92px
  );
}

.tbl-body-inner-lg {
  background: repeating-linear-gradient(
    var(--primary-6),
    var(--primary-6) 52px,
    var(--primary-4) 52px,
    var(--primary-4) 104px
  );
}
body.midnight .tbl-body-inner-lg {
  background: repeating-linear-gradient(
    var(--primary-3a2),
    var(--primary-3a2) 52px,
    var(--primary-2) 52px,
    var(--primary-2) 104px
  );
}
body.light .tbl-body-inner-lg {
  background: repeating-linear-gradient(
    var(--primary-5),
    var(--primary-5) 52px,
    var(--primary-4) 52px,
    var(--primary-4) 104px
  );
}

.tbl-cell-right-border {
  border-right: 2px solid #0095ff13;
}

.afm .afm-wrap {
  display: none;
}

.main-nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  height: 48px;
  min-width: 52px;

  transition: background-color 0.2s ease;
  letter-spacing: 0.07px;
}

.main-nav-link-active {
  font-weight: 600;
  letter-spacing: 0;
  background: var(--secondary-7);
}
body.midnight .main-nav-link-active {
  font-weight: 600;
  letter-spacing: 0;
  background: var(--secondary-5);
}

.main-nav-link-wrap {
  position: relative;
}

.main-nav-link-wrap:hover .main-nav-link-subheader {
  pointer-events: auto;
  max-height: 340px;
}

.main-nav-link-wrap:hover .main-nav-link {
  background: var(--secondary-7);
}
body.midnight .main-nav-link-wrap:hover .main-nav-link {
  background: var(--secondary-5);
}

.main-nav-link-subheader {
  /* display: none; */
  pointer-events: none;
  overflow: hidden;
  overflow: clip;
  position: absolute;
  z-index: 30;
  width: auto;
  height: auto;
  top: 48px;
  left: 0;
  max-height: 0px;
  font-size: 14px;

  background: var(--secondary-6);
  transition: max-height 0.2s ease;
}
body.midnight .main-nav-link-subheader {
  background: var(--secondary-3);
}

.bg-secondary-nav {
  background: var(--secondary-6);
}
body.midnight .bg-secondary-nav {
  background: var(--secondary-3);
}

.subnav-link {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  width: 100%;
  padding: 0 20px;
  padding-bottom: 2px;
  white-space: nowrap;
  background: var(--secondary-6);
  transition: background-color 0.15s ease;
}
body.midnight .subnav-link {
  background: var(--secondary-3);
}

.subnav-link:first-child {
  padding-top: 4px;
  height: 46px;
}

.subnav-link:last-child {
  padding-bottom: 6px;
  height: 46px;
}

.subnav-link span {
  position: relative;
}

.subnav-link span:before {
  position: absolute;
  transition: width 0.2s ease;
  bottom: -4px;
  left: -5px;
  display: block;
  height: 2px;
  width: 0%;
  content: "";
  background-color: var(--secondary-11);
}
body.midnight .subnav-link span:before {
  background-color: var(--secondary-9);
}

.subnav-link:hover span:before {
  opacity: 1;
  width: calc(100% + 10px);
}

.subnav-link:hover {
  background: var(--secondary-7);
}
body.midnight .subnav-link:hover {
  background: var(--secondary-5);
}

.secondary-nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  height: 44px;
  min-width: 80px;

  transition: background-color 0.15s ease;
  letter-spacing: 0.06px;
}

.secondary-nav-link.active,
.secondary-nav-link:hover {
  background: var(--secondary-7);
}
body.midnight .secondary-nav-link.active,
body.midnight .secondary-nav-link:hover {
  background: var(--secondary-5);
}

.secondary-nav-link span {
  position: relative;
}

.secondary-nav-link span.active {
  font-weight: 600;
  letter-spacing: 0;
}

.secondary-nav-link span:before {
  position: absolute;
  transition: width 0.2s ease;
  bottom: -4px;
  left: -5px;
  display: block;
  height: 2px;
  width: 0%;
  content: "";
  background-color: var(--secondary-11);
}
body.midnight .secondary-nav-link span:before {
  background-color: var(--secondary-9);
}

.secondary-nav-link:hover span:before,
.secondary-nav-link span.active:before {
  opacity: 1;
  width: calc(100% + 10px);
}

.drawer-nav-link {
  width: 100%;
  padding: 0 20px;
  display: flex;
  height: 48px;
  align-items: center;
  /* justify-content: center;
  text-align: center; */
  transition: background-color 0.2s ease;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  line-height: 1.2;
  letter-spacing: 0.07px;
}

.drawer-nav-link.active {
  background: var(--secondary-7);
  font-weight: 600;
  letter-spacing: 0;
}
body.midnight .drawer-nav-link.active {
  background: var(--secondary-5);
}

.drawer-nav-link:hover {
  background: var(--secondary-7);
}
body.midnight .drawer-nav-link:hover {
  background: var(--secondary-5);
}

.side-nav-primary {
  width: 180px;
  position: absolute;
  top: 16px;
  transition: all 0.2s ease;
}

.side-nav-primary-active {
  left: 0px;
  opacity: 1;
}
.side-nav-primary-hidden {
  left: -32px;
  opacity: 0;
  pointer-events: none;
}

.side-nav-secondary {
  width: 180px;
  position: absolute;
  top: 16px;
  transition: all 0.25s ease;
}

.side-nav-secondary-active {
  opacity: 1;
  left: 0px;
}
.side-nav-secondary-hidden {
  opacity: 0;
  left: 32px;
  pointer-events: none;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://cdn.tft.tools/fonts3/Montserrat-Regular.woff2) format('woff2');
  unicode-range: U+0020-007F;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://cdn.tft.tools/fonts3/Montserrat-Medium.woff2) format('woff2');
  unicode-range: U+0020-007F;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://cdn.tft.tools/fonts3/Montserrat-SemiBold.woff2) format('woff2');
  unicode-range: U+0020-007F;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format('woff2');
  unicode-range: U+0000-001F, U+0080-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format('woff2');
  unicode-range: U+0000-001F, U+0080-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format('woff2');
  unicode-range: U+0000-001F, U+0080-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

